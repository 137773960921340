/**
 * Expands/Contract faq list items
 */

import { closest, next } from "./util";

const ITEM_CLASS = "js-board-member-item";

export const handler = (el, e) => {
  const item = e.target.classList.contains(ITEM_CLASS)
    ? e.target
    : closest(e.target, "a");
  if (!item || !item.classList.contains(ITEM_CLASS)) {
    return;
  }
  e.preventDefault();

  const description = next(item.parentNode);
  const value = description.getAttribute("aria-hidden") === "false";
  description.setAttribute("aria-hidden", value);
  item.setAttribute("data-is-selected", !value);
  item.blur();
};

import { createDefaultMap } from "../mapbox/default-mapbox-map";
import { initializeMapForData } from "./mapbox-layers";
import { getData } from "./data";

import { waitForGlobal } from "../util";

const initializeMap = (token, apiUrl) => (mapboxgl) => {
  // Initialize map
  const mapBoxMap = createDefaultMap({
    mapboxgl,
    token,
  });

  // only start fetching when map is loaded to provide quicker response for user.
  mapBoxMap.on("load", () => {
    // Initialize map for markers.
    initializeMapForData({
      map: mapBoxMap,
      data: [],
      source: "citizen-science",
      mapboxgl,
    });

    // Fetch data from API
    getData(apiUrl);
  });
};

export const enhancer = (element) => {
  const token = element.getAttribute("data-mapbox-token");
  const apiUrl = element.getAttribute("data-api-url");

  // Wait until the external `mapboxgl` is loaded.
  waitForGlobal("mapboxgl", 100).then(initializeMap(token, apiUrl));
};

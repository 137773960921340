import { prefersNoMotion, prefersToSaveData } from "./util";
import { getDocWidth } from "./responsive";

const BREAKPOINT = 940;
const TAB_SELECTOR = `[role="tab"]`;
const PANEL_SELECTOR = `[role="tabpanel"]`;
const IMAGE_SELECTOR = `.js-image`;

const playOrPauseVideo = ({ video, play }) => {
  if (!video) {
    return;
  }
  if (prefersNoMotion || prefersToSaveData) {
    video.setAttribute("controls", "");
  } else {
    video.muted = true;
    video[play ? "play" : "pause"]();
  }
};

const selectTab = ({ tabs, panels, images, targetTab }) => {
  const controls = targetTab
    ? targetTab.getAttribute("aria-controls").split(" ")
    : [];
  tabs.forEach((tab) => tab.setAttribute("aria-selected", tab === targetTab));
  images.forEach((image) =>
    image.setAttribute("aria-hidden", !controls.includes(image.id))
  );
  panels.forEach((panel) => {
    const hide = !controls.includes(panel.id);
    panel.setAttribute("aria-hidden", hide);
    const video = panel.querySelector("video");
    playOrPauseVideo({ video, play: !hide });
  });
  if (!controls.length) {
    images[0].setAttribute("aria-hidden", "false");
  }
};

export const enhancer = (container) => {
  const tabs = [...container.querySelectorAll(TAB_SELECTOR)];
  const panels = [...container.querySelectorAll(PANEL_SELECTOR)];
  const images = [...container.querySelectorAll(IMAGE_SELECTOR)];

  // Add click listener for all tabs.
  tabs.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      e.preventDefault();
      // Close tab when clicked again on smaller screens.
      if (
        getDocWidth() < BREAKPOINT &&
        tab.getAttribute("aria-selected") === "true"
      ) {
        selectTab({ tabs, panels, images });
      } else {
        selectTab({ tabs, panels, images, targetTab: tab });
      }
    });
  });

  // Open first tab on 'desktop'.
  if (getDocWidth() > BREAKPOINT) {
    selectTab({ tabs, panels, images, targetTab: tabs[0] });
  }
};

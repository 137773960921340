import SweetScroll from "sweet-scroll";
import { publish } from "./observer";

// Structure
// {
//   [toggleGroupId]: {
//     activeElement: HTMLElement,
//     activeElementId: String,
//     targets: {
//       [itemId]: HTMLElement
//     },
//     triggers: {
//       [itemId]: HTMLElement
//     }
//   }
// }
const toggleGroups = {};

/**
 * Adds each element with a toggleGroup enhancer to the toggleGroups list.
 * If the element contains a id of a already existing toggleGroup, it's added to that group.
 * @param {String} groupId
 * @param {String} itemId
 * @param {HTMLElement} targetElement
 */
const addElementToGroup = (groupId, itemId, targetElement, triggerElement) => {
  toggleGroups[groupId] = {
    activeElement: null,
    activeElementId: "",
    targets: {
      ...(toggleGroups[groupId] ? toggleGroups[groupId].targets : []),
      [itemId]: targetElement,
    },
    triggers: {
      ...(toggleGroups[groupId] ? toggleGroups[groupId].triggers : []),
      [itemId]: triggerElement,
    },
  };
};

/**
 * Closes the active element of a toggle group by
 * updating the attribute and clearing the activeElement information.
 * @param {String} groupId
 */
const closeActiveElement = (groupId) => {
  const id = toggleGroups[groupId].activeElement.getAttribute(
    "data-toggle-target"
  );

  toggleGroups[groupId].activeElement.setAttribute("data-toggle-open", "false");
  toggleGroups[groupId].activeElement = null;
  toggleGroups[groupId].activeElementId = "";
  publish("toggle-group", id);
};

/**
 * Closes a specific item from a toggle group
 * @param {String} groupId
 * @param {String} elementId
 */
const closeItem = (groupId, elementId) => {
  toggleGroups[groupId].targets[elementId].setAttribute(
    "data-toggle-open",
    "false"
  );
};

/**
 * Opens a specific item from a toggle group
 * @param {String} groupId
 * @param {String} elementId
 */
const openItem = (groupId, elementId) => {
  toggleGroups[groupId].targets[elementId].setAttribute(
    "data-toggle-open",
    "true"
  );
  toggleGroups[groupId].activeElement =
    toggleGroups[groupId].targets[elementId];
  toggleGroups[groupId].activeElementId = elementId;
};

/**
 * Scrolls the item into view
 * @param {String} groupId
 * @param {String} elementId
 */
const scrollItemIntoView = (groupId, elementId) => {
  const offset = (window.innerWidth / 100) * 2.5 + 57;

  const sweetScroll = new SweetScroll({
    duration: 400,
    easing: "easeInOutCubic",
    offset: -offset,
  });
  sweetScroll.toElement(toggleGroups[groupId].triggers[elementId]);
};

/**
 * Adds received elements matching target element to specific toggleGroup
 * and automatically closes the item.
 * @param {HTMLElement} element
 */
export const enhancer = (element) => {
  const id = element.getAttribute("data-toggle-id");
  const groupId = element.getAttribute("data-toggle-group-id");

  const targetSelector = element.getAttribute("data-toggle-target-element");
  const targetElement = document.querySelector(targetSelector);

  addElementToGroup(groupId, id, targetElement, element);
  closeItem(groupId, id);
};

/**
 * Executed on for each click on a toggleGroupTrigger.
 *
 * Closes the already active item.
 * Open the clicked element if it wasn't the previous opened element.
 * @param {HTMLElement} element
 * @param {ClickEvent} event
 */
export const handler = (element, event) => {
  event.preventDefault();

  const id = element.getAttribute("data-toggle-id");
  const groupId = element.getAttribute("data-toggle-group-id");

  const clickedElementIsNotTheCurrentlyActiveElement =
    toggleGroups[groupId].activeElementId !== id;

  if (toggleGroups[groupId].activeElement) {
    closeActiveElement(groupId);
  }

  if (clickedElementIsNotTheCurrentlyActiveElement) {
    openItem(groupId, id);
    scrollItemIntoView(groupId, id);
  }
};

import srcsetLib from "srcset";
import { closest } from "@grrr/utils";
import { getDocWidth } from "./responsive";
import { htmlToElement } from "./util";

/**
 * Enhance images with zoom button.
 * - In WYSIWYG replaces wrapping anchors with a clickable zoom button, and replaces the
 * full size link with a more appropriate size (first src from srcset larger
 * than the current viewport).
 * - In regular `figure` can wrap child image and add button.
 */

const getSource = (srcset) => {
  const docWidth = getDocWidth();
  const parsedSources = srcsetLib.parse(srcset);
  const largerSources = parsedSources.filter((src) => src.width >= docWidth);
  if (parsedSources.length && !largerSources.length) {
    return parsedSources[0].url;
  }
  return parsedSources.reduce((acc, src) => {
    if (Number.isNaN(acc)) {
      return acc;
    }
    if (src.width > docWidth) {
      return src.url;
    }
    return acc;
  }, "");
};

const getAnchor = closest((el) => el.tagName.toLowerCase() === "a");

const getButton = (url) =>
  htmlToElement(`
  <a class="zoomable-image__button" data-handler="imageOverlay" href="${url}" aria-label="View larger image"></a>
`);

/**
 * Enhance single images (as used in `mediaSlider`).
 */
const addButton = (parent, srcset) => {
  const url = getSource(srcset);
  if (!url) {
    return;
  }

  parent.appendChild(getButton(url));
};

/**
 * Enhance images wrapped inside anchors.
 */
const addButtonFromWysiwyg = (image) => {
  const anchor = getAnchor(image);
  if (!anchor || !image.srcset) {
    return;
  }
  const url = getSource(image.srcset);
  if (!url) {
    return;
  }

  const wrapper = document.createElement("div");
  wrapper.classList.add("zoomable-image");
  wrapper.appendChild(image);
  wrapper.appendChild(getButton(url));

  anchor.parentNode.insertBefore(wrapper, anchor);
  anchor.parentNode.removeChild(anchor);
};

export const finderEnhancer = (container) => {
  const images = container.querySelectorAll("img");
  [...images].forEach(addButtonFromWysiwyg);
};

export const enhancer = (figure) => {
  const srcset = figure.getAttribute("data-zoom-srcset");
  addButton(figure, srcset);
};

import { isSafari } from "./util";

/**
 * This is a workaround to align the colors of the background and the colors in the mp4 video for
 * Safari only. Safari doesn't support webM, so no transparent video is possible, but renders
 * another background color for the mp4 videos than other browsers (think IE11).
 * Set a data-attribute that indicates another color should be used as the background-color.
 */
export const enhancer = (element) => {
  if (isSafari()) {
    element.setAttribute("data-special-color", "true");
  }
};

import { publish, subscribe } from "./observer";
import { sunglassesUpdate, sunglassesClose } from "./observer-subjects";

/**
 * Go to the next or first slide
 */
const handleNextTrigger = (element, maxCount) => (event) => {
  const currentCount = Number(element.getAttribute("data-active-slide"));

  const newCount = currentCount + 1 > maxCount ? 1 : currentCount + 1;
  publish(sunglassesUpdate, { index: String(newCount) });
};
/**
 * Go to the previous or last slide
 */
const handlePreviousTrigger = (element, maxCount) => (event) => {
  const currentCount = Number(element.getAttribute("data-active-slide"));

  const newCount = currentCount - 1 < 1 ? maxCount : currentCount - 1;
  publish(sunglassesUpdate, { index: String(newCount) });
};

/**
 * Close info-box and set total block to inactive
 */
const handleCloseTrigger = () => {
  // Set the image to image 2
  publish(sunglassesUpdate, { index: "2" });
  publish(sunglassesClose);
};

/**
 * Called by observer
 * Close the info-box
 */
const handleClose = (element) => () => {
  element.setAttribute("aria-hidden", "true");
};
/**
 * Called by observer
 * Open the info-box
 */
const handleUpdate = (element) => () => {
  element.setAttribute("aria-hidden", "false");
};
/**
 * Called by observer
 * Update the data of the triggers
 */
const updateTriggerActiveSlide = (triggers) => ({ index }) =>
  triggers.forEach((trigger) =>
    trigger.setAttribute("data-active-slide", index)
  );

/**
 * Sunglasses content box
 *
 * Handle click of all triggers in the box and manage box state
 */
export const enhancer = (element) => {
  // Get elements
  const nextTrigger = element.querySelector(".js-next-trigger");
  const previousTrigger = element.querySelector(".js-previous-trigger");
  const closeTrigger = element.querySelector(".js-close-trigger");

  // Set event listeners
  nextTrigger.addEventListener(
    "click",
    handleNextTrigger(
      nextTrigger,
      Number(nextTrigger.getAttribute("data-max-slides"))
    )
  );
  previousTrigger.addEventListener(
    "click",
    handlePreviousTrigger(
      previousTrigger,
      Number(nextTrigger.getAttribute("data-max-slides"))
    )
  );
  closeTrigger.addEventListener("click", handleCloseTrigger);

  // Add observer subscriptions.
  subscribe(
    sunglassesUpdate,
    updateTriggerActiveSlide([nextTrigger, previousTrigger])
  );
  subscribe(sunglassesClose, handleClose(element));
  subscribe(sunglassesUpdate, handleUpdate(element));
};

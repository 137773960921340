import { htmlToElement } from "@grrr/utils";

/**
 * Image Overlay
 * Opens clicked image link in an overlay with a larger image.
 */
export const handler = (el, e) => {
  const src = el.getAttribute("data-src") || el.href;
  const srcset = el.getAttribute("data-srcset") || el.href;
  if (!src) {
    return;
  }
  e.preventDefault();

  const overlay = htmlToElement(`
    <div class="image-overlay">
      <div class="image-overlay__inner">
        <div class="image-overlay__wrapper">
          <div class="image-overlay__placeholder">
            <img src="${src}" srcset="${srcset}">
            <button class="image-overlay__close" aria-label="Close overlay">
              <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="2">
                  <path d="M13.607 2.54L2.335 13.81M13.834 14.098L2.049 2.313"/>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  `);
  document.body.appendChild(overlay);

  // Close handler
  const closeHandler = (closeEvent) => {
    if (closeEvent.keyCode && closeEvent.keyCode !== 27) {
      return;
    }
    if (closeEvent.target.tagName.toLowerCase() === "img") {
      return;
    }
    closeEvent.preventDefault();
    document.removeEventListener("keyup", closeHandler);
    overlay.parentNode.removeChild(overlay);
  };

  // Attach eventListeners
  overlay.addEventListener("click", closeHandler);
  document.addEventListener("keyup", closeHandler);
};

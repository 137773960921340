import { htmlToElement } from "@grrr/utils";
import { getShareLink } from "./social-share-links";
import { matchesBreakpoint } from "./responsive";

/**
 * Create a basic social share button (actually a link).
 */
export const create = ({ type, ...shareOptions }) => {
  const button = htmlToElement(`
    <a data-type="${type}" href="${getShareLink({ type, ...shareOptions })}"
      target="_blank" rel="external noopener noreferrer">
        <span class="sr-only">Share on ${type}</span>
    </a>
  `);
  if (type !== "mail") {
    button.setAttribute("data-handler", "socialShareButton");
  }
  return button;
};

export const handler = (el, e) => {
  if (!matchesBreakpoint("medium")) {
    return;
  }
  e.preventDefault();
  window.open(el.href, "pop", "width=700, height=500, scrollbars=no");
};

/**
 * Add class to the body when scrolling.
 * This class disabled pointer-events in the CSS. Greatly enhanced performance.
 */

import { register as registerScrollListener } from "./scroll-listener";

const TIMEOUT = 200;

const disableHoverStylesOnScroll = () => {
  const { body } = document;
  let timer;

  registerScrollListener("hover-styles", () => {
    clearTimeout(timer);
    if (!body.classList.contains("disable-hover")) {
      body.classList.add("disable-hover");
    }
    timer = setTimeout(() => {
      body.classList.remove("disable-hover");
    }, TIMEOUT);
  });
};

export default disableHoverStylesOnScroll;

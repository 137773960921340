import { publish, subscribe } from "../observer";
import {
  showInfoBox,
  hideInfoBox as hideInfoBoxSubject,
} from "../observer-subjects";

let infoBox = null;
let trigger = null;

/**
 * Called by observer.
 * Show info box.
 */
const showInfoBoxHandler = (data) => {
  infoBox.setAttribute("aria-hidden", false);
  trigger.setAttribute("aria-expanded", true);
};

/**
 * Called by observer.
 * Hide info box.
 */
const hideInfoBoxHandler = (data) => {
  infoBox.setAttribute("aria-hidden", true);
  trigger.setAttribute("aria-expanded", false);
};

/**
 * Exported hide info-box function.
 */
export const hideInfoBox = () => {
  publish(hideInfoBoxSubject);
};

/**
 * Show/Hide info-box.
 * Controlled by Observer.
 */
export const enhancer = (element) => {
  // Assign element to global.
  infoBox = element;
  trigger = element.querySelector(".js-map-side-bar-trigger");

  // Subscribe function to observer.
  subscribe(hideInfoBoxSubject, hideInfoBoxHandler);
  subscribe(showInfoBox, showInfoBoxHandler);

  // Hide info box by default.
  publish(hideInfoBoxSubject);
};

/**
 * Polyfill for object-fit
 * @see: https://github.com/bfred-it/object-fit-images/
 */

import objectFitImages from "object-fit-images";

export const enhancer = (el) => {
  if (Modernizr.objectfit) {
    return;
  }
  objectFitImages(el);
};

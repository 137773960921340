const SITE_BANNER_SELECTOR = ".js-site-banner";

const STORAGE_KEY = "toc_site_banner_closed";

/**
 * This will cause a cumulative layout shift if the element contains position relative.
 * Only use when the element isn't positioned relative.
 */
export const enhancer = (banner) => {
  const storageKey =
    banner.getAttribute("data-custom-storage-key") || STORAGE_KEY;

  if (!sessionStorage.getItem(storageKey)) {
    banner.setAttribute("aria-hidden", "false");
  }
};

/**
 * This will cause a cumulative layout shift if the element contains position relative.
 * Only use when the element isn't positioned relative.
 */
export const handler = (el, e) => {
  const storageKey = el.getAttribute("data-custom-storage-key") || STORAGE_KEY;

  sessionStorage.setItem(storageKey, "true");
  const banner = document.querySelector(SITE_BANNER_SELECTOR);
  if (!banner) {
    return;
  }
  banner.setAttribute("aria-hidden", "true");
};

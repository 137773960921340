import { subscribe } from "../observer";
import {
  trashTrackerHasSubmitted,
  trashTrackerIsDoneDrawing,
  trashTrackerResetForm,
  trashTrackerError,
} from "../observer-subjects";

/**
 * Called by observer.
 * Show Element.
 */
const show = (element) => () => {
  element.setAttribute("data-visible", "true");
};

/**
 * Called by observer.
 * Hide Element.
 */
const hide = (element) => () => {
  element.setAttribute("data-visible", "false");
};

export const enhancer = (element) => {
  subscribe(trashTrackerError, show(element));
  subscribe(trashTrackerIsDoneDrawing, show(element));
  subscribe(trashTrackerResetForm, hide(element));
  subscribe(trashTrackerHasSubmitted, hide(element));
};

/* eslint-disable camelcase */

import { subscribe } from "./observer";
import { matchesBreakpoint } from "./responsive";

import { systemsDashboardReceivedData } from "./observer-subjects";

export const SOURCE_NAME = "systems";
export const MARKER_LAYER = "markers";
export const CLUSTER_LAYER = "clusters";

export const MapboxData = ({ mapboxgl, token }) => {
  const riverSystemFeatures = window.RIVER_SYSTEM_FEATURES;
  const oceanSystemFeatures = window.OCEAN_SYSTEM_FEATURES;
  const cmsFeatures = [...riverSystemFeatures, ...oceanSystemFeatures].map(
    /**
     * START
     * This code is a pragmatic solution to reset the coordinates of system 3
     * Remove this code mid march 2024.
     */
    (system) => {
      if (system.hash === "system03") {
        const newSystem = { ...system };
        newSystem.trajectory.splice(1);

        newSystem.geometry.coordinates = ["-123.39155", "48.414765"];

        return newSystem;
      }

      return system;
    }
    /**
     * END
     * This code is a pragmatic solution to reset the coordinates of system 3 for just a view months.
     * Remove this code mid march 2024.
     */
  );

  let APIFeatureIds = [];
  let isMapInitialized = false;

  /**
   * Set access token.
   */
  mapboxgl.accessToken = token;

  /**
   * Initialize map.
   */
  const map = new mapboxgl.Map({
    container: "dashboard-map",
    style: "mapbox://styles/theoceancleanup/cl8sdgtli000914mkv18shm2p",
    center: [95, 5],
    zoom: 1.4,
    interactive: true,
    scrollZoom: true,
    doubleClickZoom: true,
    attributionControl: false,
    logoPosition: "bottom-left",
  });

  /**
   * Attach controls to the Mapbox map.
   */
  const attachControls = () => {
    const controls = [
      new mapboxgl.AttributionControl({
        compact: false,
      }),
    ];
    if (matchesBreakpoint("small")) {
      controls.push(
        new mapboxgl.NavigationControl({
          showCompass: false,
          showZoom: true,
        })
      );
    }
    controls.forEach((control) => map.addControl(control));
  };

  /**
   * Add the GeoJSON source.
   */
  const addSources = (sourceName, systemFeatures) => {
    // Make features from the additional-locations.
    const additionalFeatures = systemFeatures

      .filter((system) => system.additional_locations)
      .reduce((acc, system) => {
        const additionalLocations = system.additional_locations.map(
          // Make an object based on the system with the coordinates of the additional location.
          ({ additional_latitude, additional_longitude, hash }) => ({
            ...system,
            geometry: {
              coordinates: [additional_longitude, additional_latitude],
              type: "Point",
            },
            properties: {
              ...system.properties,
              hash: `${system.properties.hash}&&${hash}`,
              // Add additional locations(even when it's an additional location itself)
              additionalLocations: [
                // Add the main systems geometry as additional location so it's linked as additional location.
                system.geometry.coordinates,
                ...system.additional_locations
                  // Remove this additional feature from this list as it's now the primary.
                  .filter(
                    (a) => a.additional_longitude !== additional_longitude
                  )
                  .map((a) => [a.additional_longitude, a.additional_latitude]),
              ],
            },
          })
        );

        return [...acc, ...additionalLocations];
      }, []);

    // Add additional locations to the properties property to be used in displaying additional active markers on the map.
    const systemFeaturesWithAdditionalLocations = systemFeatures.map(
      (feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          // Add the additional locations of an interceptor (if they are present).
          additionalLocations: feature.additional_locations
            ? feature.additional_locations.map((a) => [
                a.additional_longitude,
                a.additional_latitude,
              ])
            : [],
        },
      })
    );

    map.addSource(sourceName, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          ...systemFeaturesWithAdditionalLocations,
          ...additionalFeatures,
        ],
      },
      cluster: true,
      clusterMaxZoom: 8,
      clusterRadius: 50,
    });
  };

  /**
   * Add the trajectory(paths) of the ocean systems.
   */
  const addTrajectories = () => {
    oceanSystemFeatures
      .map((feature) => {
        // If there isn't a trajectory (Draft system)
        // Don't try to draw it.
        if (!feature.trajectory) return [];
        return feature.trajectory
          .filter((single) => {
            // Filter the items that are not updated in the last X days
            const now = new Date();
            const singleDate = new Date(single.time);
            const limit = feature.trajectory_day_limit || 10000;
            const differenceInTime = now.getTime() - singleDate.getTime();
            const differentInDays = differenceInTime / (1000 * 3600 * 24);
            return differentInDays <= limit;
          })
          .map((single) => [single.longitude, single.latitude]);
      })
      .forEach((trajectory, index) => {
        // Add source.
        map.addSource(`trajectory-${index}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: trajectory,
            },
          },
        });
      });
  };

  /**
   * Add all layers (markers and clusters).
   */
  const addLayers = (sourceName) => {
    map.addLayer({
      id: CLUSTER_LAYER,
      type: "circle",
      source: sourceName,
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#ffffff",
          100,
          "#f1f075",
          750,
          "#f28cb1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: sourceName,
      filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-size": 14,
      },
    });

    map.addLayer({
      id: MARKER_LAYER,
      type: "circle",
      source: sourceName,
      filter: ["!", ["has", "point_count"]],
      paint: {
        // Note: These colors match the status-color combinations set in system-dashboard-status.scss.
        "circle-color": [
          "match",
          ["get", "status"],
          "planned",
          "rgba(165, 165, 165, 0.75)",
          "contract_signed",
          "rgba(165, 165, 165, 0.75)",
          "build_started",
          "rgba(123, 192, 222, 0.75)",
          "build_completed",
          "rgba(123, 192, 222, 0.75)",
          "installed_for_testing",
          "rgba(1, 203, 225, 0.75)",
          "active",
          "rgba(81, 207, 102, 0.75)",
          "in_operation",
          "rgba(81, 207, 102, 0.75)",
          "deployment",
          "rgba(81, 207, 102, 0.75)",
          "harvesting",
          "rgba(81, 207, 102, 0.75)",
          "extraction",
          "rgba(81, 207, 102, 0.75)",
          "port_call",
          "rgba(255, 240, 124, 0.75)",
          "port call / mobilisation",
          "rgba(255, 240, 124, 0.75)",
          "transit",
          "rgba(255, 240, 124, 0.75)",
          "recovery",
          "rgba(255, 240, 124, 0.75)",
          "technical_downtime",
          "rgba(255, 240, 124, 0.75)",
          "weather_downtime",
          "rgba(255, 240, 124, 0.75)",
          "standby",
          "rgba(255, 240, 124, 0.75)",
          "in_maintenance",
          "rgba(255, 240, 124, 0.75)",
          "operation_paused",
          "rgba(255, 240, 124, 0.75)",
          // When no value matches.
          "rgba(255, 255, 255, 0.25)",
        ],
        "circle-radius": 9,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#fff",
      },
    });

    oceanSystemFeatures.forEach((feature, index) => {
      map.addLayer({
        id: `trajectory-${index}`,
        type: "line",
        source: `trajectory-${index}`,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#01cbe1",
          "line-width": 1,
        },
      });
    });
  };

  const addDataToMap = () => {
    if (!APIFeatureIds.length || !cmsFeatures.length || isMapInitialized)
      return;

    const systemFeatures = cmsFeatures.filter((feature) => {
      return APIFeatureIds.includes(Number(feature.properties.id));
    });

    addSources(SOURCE_NAME, systemFeatures);
    addTrajectories();
    addLayers(SOURCE_NAME);
    isMapInitialized = true;
  };

  return {
    init() {
      return new Promise((resolve, reject) => {
        attachControls();

        subscribe(systemsDashboardReceivedData, (data) => {
          APIFeatureIds = data.systems.map((system) => system.id);
          addDataToMap();
        });

        map.on("load", (e) => {
          addDataToMap();
        });

        resolve({ map, sourceName: SOURCE_NAME });
      });
    },
  };
};

/**
 * Set data attribute if the input has any value.
 */
const handleInput = (event) => {
  event.target.setAttribute("data-has-value", event.target.value.length > 0);
};

/**
 * Set data attribute if the input has any value.
 */
export const enhancer = (element) => {
  element.addEventListener("input", handleInput);
};

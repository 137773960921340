import { debounce, filter, throttle } from "./util";
import { register as registerScrollListener } from "./scroll-listener";

/**
 * Lazyload iframes when scrolled into view
 */

let iframes;
let screenHeight;
let totalCount = 0;
let loadedCount = 0;

const swapSrc = (el) => {
  if (!el.getAttribute("data-src")) {
    return;
  }
  el.src = el.getAttribute("data-src");
  el.removeAttribute("data-src");
};

function scrolledPastTrigger(item) {
  const dims = item.getBoundingClientRect();
  return dims.top <= screenHeight;
}

function scrollHandler(e) {
  if (loadedCount >= totalCount) {
    return;
  }
  const scrolledPastTriggers = [...filter(iframes, scrolledPastTrigger)];
  scrolledPastTriggers.forEach((i) => {
    if (!i.getAttribute("data-src")) {
      return;
    }
    swapSrc(i);
    loadedCount += 1;
  });
}

function findLazyFrames() {
  iframes = [...document.querySelectorAll("iframe")];
  if (!iframes.length) {
    return;
  }
  iframes.forEach((i) => {
    if (!i.getAttribute("data-src")) {
      return;
    }
    if (i.getAttribute("data-lazyload") === "false") {
      swapSrc(i);
    } else if (i.getAttribute("data-src")) {
      totalCount += 1;
    }
  });
  if (!totalCount) {
    return;
  }
  screenHeight = document.documentElement.clientHeight;
  registerScrollListener("lazy-iframes", throttle(scrollHandler, 100));
}

export default () => {
  window.setTimeout(() => {
    findLazyFrames();
  }, 0);

  window.addEventListener(
    "resize",
    debounce((e) => {
      screenHeight = document.documentElement.clientHeight;
    }, 300)
  );
};

import { htmlToElement } from "@grrr/utils";

const getVideoEmbed = (anchor) => {
  const html = anchor.getAttribute("data-video-html");
  if (html) {
    return html;
  }
  return `<video src="${anchor.href}" width="1280" height="720" controls preload="metadata" autoplay></video>`;
};

const createOverlay = (embed) =>
  htmlToElement(`
  <div class="video-overlay">
    <div class="video-overlay__inner">
      <div class="video-overlay__wrapper">
        <div class="video-overlay__placeholder">
          ${embed}
          <button class="video-overlay__close" aria-label="Close overlay">
            <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="2">
                <path d="M13.607 2.54L2.335 13.81M13.834 14.098L2.049 2.313"/>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
`);

/**
 * Opens clicked video link in an overlay with HTML5 player or iframe embed.
 */
export const handler = (anchor, e) => {
  const embed = getVideoEmbed(anchor);
  if (!embed) {
    return;
  }
  e.preventDefault();

  // Create and append embed.
  const overlay = createOverlay(embed);
  document.body.appendChild(overlay);

  // Handle close event.
  const closeHandler = (closeEvent) => {
    if (closeEvent.keyCode && closeEvent.keyCode !== 27) {
      return;
    }
    closeEvent.preventDefault();
    document.removeEventListener("keyup", closeHandler);
    overlay.parentNode.removeChild(overlay);
  };

  // Attach event listeners.
  overlay.querySelector("button").addEventListener("click", closeHandler);
  document.addEventListener("keyup", closeHandler);
};

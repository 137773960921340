import { matchesBreakpoint } from "./responsive";

export const handler = (element, event) => {
  // Close overlaying navigation.
  if (!matchesBreakpoint("large")) {
    const target = document.querySelector(".js-brandguide-navigation");
    target.classList.toggle("is-expanded");
  }

  // Remove disabled body attribute.
  if (!matchesBreakpoint("large")) {
    const body = document.querySelector("body");
    body.setAttribute("data-brandguide-navigation-scroll-disabled", "false");
  }
};

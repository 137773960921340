/**
 * Expands/Collaps metrics box
 */

const METRICS_SELECTOR = ".js-mapbox-metrics";

// const showDefinition
const showMetrics = (el, metricsContent) => {
  el.setAttribute("aria-expanded", true);
  el.blur();
  metricsContent.classList.add("is-expanded");
};

// const hideDefinition
const hideMetrics = (el, metricsContent) => {
  el.setAttribute("aria-expanded", false);
  el.blur();
  metricsContent.classList.remove("is-expanded");
};

export const handler = (el, e) => {
  const metricsContent = el.parentNode.querySelector(METRICS_SELECTOR);
  e.preventDefault();

  if (el.getAttribute("aria-expanded") === "true") {
    hideMetrics(el, metricsContent);
  } else {
    showMetrics(el, metricsContent);
  }
};

/**
 * Adds e-mail address to form and submits it
 */

export const enhancer = (el) => {
  const action = el.getAttribute("data-action");

  el.addEventListener("submit", (e) => {
    e.preventDefault();
    const email = el.querySelector('[type="email"]').value;
    window.open(action.replace("mailto:", `mailto:${email}`), "_blank");
  });
};

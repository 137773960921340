import { subscribe } from "../observer";
import { updateCitizenScienceData } from "../observer-subjects";
import getLatestAddedSurvey from "./get-latest-added-survey";

/**
 * Format number to display format.
 */
const formatNumberToDisplayFormat = (amount) =>
  new Intl.NumberFormat("nl-NL").format(amount);

/**
 * Update the total amount of surveys number.
 */
const updateAmountOfSurveys = (element) => (data) => {
  element.innerHTML = formatNumberToDisplayFormat(data.length);
};

/**
 * Update the total amount of counted items number.
 */
const updateAmountOfItems = (element) => (data) => {
  // Combine all counted items.
  const totalAmountOfItemsCounted = data.reduce((total, count) => {
    return total + count.properties.totalItemsCount;
  }, 0);

  element.innerHTML = formatNumberToDisplayFormat(totalAmountOfItemsCounted);
};

/**
 * Update the latest added survey.
 */
const updateLatestAddedSurvey = (elements) => (data) => {
  const latestAddedSurvey = getLatestAddedSurvey(data);
  const { id } = latestAddedSurvey;
  const { type, dateTime, latLong, utcDate } = latestAddedSurvey.properties;

  elements.type.innerHTML = type;
  elements.date.innerHTML = dateTime;
  elements.date.setAttribute("datetime", utcDate);
  elements.id.innerHTML = id;
  elements.trigger.setAttribute("data-lat-long", latLong);
};

/**
 * Called by observer.
 * Updates the content of the template and mounts template to DOM.
 */
const updateContent = (template, elements) => (data) => {
  if (elements.amountOfSurveys) {
    updateAmountOfSurveys(elements.amountOfSurveys)(data);
  }
  if (elements.amountOfItems) {
    updateAmountOfItems(elements.amountOfItems)(data);
  }

  if (elements.type && elements.date && elements.id && elements.trigger) {
    updateLatestAddedSurvey(elements)(data);
  }

  elements.target.appendChild(template);
};

export const enhancer = (template) => {
  const templateClone = template.content.cloneNode(true);

  const elements = {
    type: templateClone.querySelector(".js-type-of-sighting"),
    date: templateClone.querySelector(".js-date-added"),
    id: templateClone.querySelector(".js-survey-id"),
    trigger: templateClone.querySelector(".js-latest-added-survey-trigger"),

    target: document.querySelector(".js-citizen-science-meta-target"),

    amountOfItems: templateClone.querySelector(".js-amount-of-items"),
    amountOfSurveys: templateClone.querySelector(".js-amount-of-surveys"),
  };

  subscribe(updateCitizenScienceData, updateContent(templateClone, elements));
};

import { htmlToElement } from "@grrr/utils";

let currentMarkerNode = null;

/**
 * Remove active marker from the map.
 */
export const removeActiveMarker = () => {
  if (currentMarkerNode) {
    currentMarkerNode.parentNode.removeChild(currentMarkerNode);
    currentMarkerNode = null;
  }
};

/**
 * Add active marker to the map.
 */
export const addActiveMarker = (mapboxgl, map, coordinates) => {
  // Create new active marker.
  currentMarkerNode = htmlToElement(
    `<div class="systems-dashboard__active-marker"></div>`
  );

  // Add new active marker to the map.
  new mapboxgl.Marker(currentMarkerNode).setLngLat(coordinates).addTo(map);
};

/**
 * Transform seconds to minutes.
 */
export default (seconds) => {
  // Round the value to ensure 62 = 1 and 91 = 2.
  const result = Math.round(seconds / 60);

  // When the amount of seconds is below the 60, return 1 minute.
  return result === 0 ? 1 : result;
};

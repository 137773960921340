/**
 * Lazy-load video's after the user has chosen to play it.
 */

const PLAYING_CLASS = "is-playing-audio";

function addCloseButton(el) {
  const button = document.createElement("button");
  button.classList.add("audio__close");
  button.setAttribute("data-handler", "lazyAudioEmbedClose");
  button.setAttribute("aria-label", "Close audio");
  el.appendChild(button);
}

function closeAudio(button) {
  const audioContainer = button.parentNode.querySelector(".js-audio-container");
  audioContainer.parentNode.removeChild(audioContainer);
  button.parentNode.classList.remove(PLAYING_CLASS);
  button.parentNode.removeChild(button);
}

function embedAudio(el) {
  const type = el.getAttribute("data-audio-type");
  const id = el.getAttribute("data-audio-id");
  if (!type || type !== "soundcloud" || !id) {
    return;
  }

  const container = document.createElement("div");
  container.classList.add("audio-embed", "js-audio-container");
  el.parentNode.appendChild(container);

  const iframe = document.createElement("iframe");
  iframe.width = 1280;
  iframe.height = 720;
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("allowfullscreen", "");

  if (type === "soundcloud") {
    iframe.src = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&amp;color=%2301cbe1&amp;auto_play=true&amp;hide_related=true&amp;show_comments=false&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true`;
  } else {
    // nothing for now
  }
  container.appendChild(iframe);

  el.parentNode.classList.add(PLAYING_CLASS);
  addCloseButton(el.parentNode);
}

export const play = (el, e) => {
  e.preventDefault();
  embedAudio(el);
};

export const close = (el, e) => {
  e.preventDefault();
  closeAudio(el);
};

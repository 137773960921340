import { debounce } from "@grrr/utils";

/**
 * Compare images
 */
import Draggabilly from "draggabilly";

const HANDLE = ".js-handle";
const COMPARE_IMAGE = ".js-compare-image";

const getWidth = (el) => el.getBoundingClientRect().width;

const init = (el) => {
  const handle = el.querySelector(HANDLE);
  const compareImage = el.querySelector(COMPARE_IMAGE);

  const draggie = new Draggabilly(handle, {
    axis: "x",
    containment: false,
  });

  draggie.on("dragMove", (e, pointer, moveVector) => {
    const containerWidth = getWidth(el);
    const pos = draggie.position.x;
    if (pos < 0 || pos > containerWidth) {
      return;
    }
    const width = `${(pos / containerWidth) * 100}%`;
    compareImage.style.width = width;
  });

  draggie.on("dragEnd", (e, pointer) => {
    const containerWidth = getWidth(el);
    const pos = draggie.position.x;
    if (pos < 0) {
      handle.style.transform = "translate3d(0, 0, 0)";
      handle.style.left = "0px";
      compareImage.style.width = "0%";
      return;
    }
    if (pos > containerWidth) {
      handle.style.transform = "translate3d(0, 0, 0)";
      handle.style.left = `${containerWidth}px`;
      compareImage.style.width = "100%";
    }
  });

  handle.addEventListener("click", (e) => e.preventDefault());

  const resizeHandler = (e) => {
    const containerWidth = getWidth(el);
    handle.style.transform = "translate3d(0, 0, 0)";
    handle.style.left = `${containerWidth / 2}px`;
    compareImage.style.width = "50%";
  };

  window.addEventListener("resize", debounce(resizeHandler, 300), false);
};

export const enhancer = (el) => {
  init(el);
};

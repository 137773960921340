/* eslint-disable import/no-cycle */

import { htmlToElement } from "@grrr/utils";
import { MARKER_ID } from "./sources-map-mapbox";
import { ShareButtons } from "./share-buttons";

const MAIN_METRICS_MAPPER = {
  ME_kg_text: {
    label: "of plastic emission per year",
    suffix: " kg",
  },
  MPW: {
    label: "mismanaged plastic per year",
    suffix: " kg",
  },
};

const METADATA_MAPPER = {
  Me_kg_text: {
    label: "Plastic emission",
    suffix: " kg/year",
  },
  MPW: {
    label: "Mismanaged plastic",
    suffix: " kg/year",
  },
  Area_km2: {
    label: "Surface area",
    suffix: " km²",
  },
  Pop: {
    label: "Population",
    suffix: "",
  },
  Rain: {
    label: "Rainfall",
    suffix: " mm/year",
  },
  P_E__: {
    label: "Probability of plastic waste to reach the ocean*",
    suffix: "%",
  },
};

export const SourcesMapPopup = ({ content, sharingOptions }) => {
  /**
   * Create share options for pop-up template.
   */
  const createShareOptions = (properties) => {
    const id = properties[MARKER_ID];
    if (!id) {
      return "";
    }

    // Create share buttons with marker id appended.
    const shareButtons = new ShareButtons({
      ...sharingOptions,
      link: `${sharingOptions.link}?id=${id}`,
      buttonLabel: "Share this river",
    });

    return shareButtons.render();
  };

  /**
   * Create list of metadata.
   */
  const createMetadataList = (properties) => {
    const items = Object.entries(METADATA_MAPPER).reduce(
      (acc, [key, entries]) => {
        const value = properties[key];
        if (!value) {
          return acc;
        }
        return `${acc}
        <dt>${entries.label}</dt>
        <dd>${value}${entries.suffix}</dd>
      `;
      },
      ""
    );
    return `<dl aria-label="List with metadata">${items}</dl>`;
  };

  /**
   * Create list of main metrics.
   */
  const createMainMetricsList = (properties) => {
    const items = Object.entries(MAIN_METRICS_MAPPER).reduce(
      (acc, [key, entries]) => {
        const value = properties[key];
        if (!value) {
          return acc;
        }
        return `${acc}
        <li>
          <span>${value}${entries.suffix}</span>
          <span>${entries.label}</span>
        </li>
      `;
      },
      ""
    );
    return `<ul aria-label="List with main metrics">${items}</ul>`;
  };

  /**
   * Create pop-up template.
   */
  const createTemplate = (properties) => {
    return `
      <div class="sources-map-popup">
        <header class="sources-map-popup__header">
          <h4>About this river</h4>
        </header>
        <section class="sources-map-popup__main" id="sources-map-popup-main">
          ${createMainMetricsList(properties)}
        </section>
        <section class="sources-map-popup__metadata" id="sources-map-popup-metadata" aria-hidden="true">
          ${createMetadataList(properties)}
          <p>${content.metadata}</p>
        </section>
        <section class="sources-map-popup__actions">
          <button data-handler="sourcesMapPopupButton" data-type="expand" aria-controls="sources-map-popup-main sources-map-popup-metadata">More data about this river</button>
          <button data-handler="sourcesMapPopupButton" data-type="contract" aria-controls="sources-map-popup-main sources-map-popup-metadata" aria-hidden="true">Less data about this river</button>
        </section>
        <aside class="sources-map-popup__share">
          <p>${content.sharing}</p>
          <div class="share-buttons">
            ${createShareOptions(properties)}
          </div>
        </aside>
      </div>
    `;
  };

  return {
    el(properties) {
      return htmlToElement(createTemplate(properties));
    },
    html(properties) {
      return createTemplate(properties);
    },
  };
};

export const handler = (button, e) => {
  e.preventDefault();

  const contraButton = button.parentNode.querySelector('[aria-hidden="true"]');
  const targets = document.querySelectorAll(
    button
      .getAttribute("aria-controls")
      .split(" ")
      .map((target) => `#${target}`)
      .join(", ")
  );

  [...targets, button, contraButton].forEach((target) => {
    target.setAttribute(
      "aria-hidden",
      target.getAttribute("aria-hidden") !== "true"
    );
  });
};

/**
 * Build a custom marker.
 *
 * Used when the marker data structure isn't available, but should be sent to mapbox either way.
 */
export default (feature, { customHash, additionalLocations, coordinates }) => ({
  id: feature.id,
  properties: {
    hash: customHash,
    id: feature.id,
    status: feature.status,
    additionalLocations,
  },
  geometry: {
    coordinates,
  },
});

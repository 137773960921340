// twinElement
// Structure
// {
//   [id]: [element, element]
// }
const twinElements = {};

/**
 * Registers elements based on the twin-element-id.
 * All elements with the same id will be placed in the same array.
 * @param {} id
 * @param {*} element
 */
const registerTwinElement = (id, element) => {
  twinElements[id] = [...(twinElements[id] || []), element];
};

/**
 * Called on mouseenter or focus event
 * Updates data attr of all related elements based on the id.
 * @param {String} id
 */
const handleEnter = (id) => {
  twinElements[id].forEach((element) => {
    element.setAttribute("data-twin-element-hover", "active");
  });
};

/**
 * Called on mouseleave or blur event
 * Updates data attr of all related elements based on the id.
 * @param {String} id
 */
const handleLeave = (id) => {
  twinElements[id].forEach((element) => {
    element.setAttribute("data-twin-element-hover", "inactive");
  });
};

/**
 * Adds data-attr for all related elements
 * when one of the elements is in focus or hovered.
 * @param {HTMLElement} element
 */
export const enhancer = (element) => {
  const twinElementId = element.getAttribute("data-twin-id");

  registerTwinElement(twinElementId, element);

  element.addEventListener("focus", () => handleEnter(twinElementId));
  element.addEventListener("mouseenter", () => handleEnter(twinElementId));

  element.addEventListener("blur", () => handleLeave(twinElementId));
  element.addEventListener("mouseleave", () => handleLeave(twinElementId));

  handleLeave(twinElementId);
};

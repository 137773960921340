/* eslint-disable no-unused-vars */

import Flickity from "flickity";

export const enhancer = (el) => {
  const flkty = new Flickity(el, {
    autoPlay: 10000,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: true,
    arrowShape:
      "M27.13 51.227L76.795 1.57 75.226 0 24 51.227 72.773 100l1.57-1.57",
  });
};

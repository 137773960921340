import { htmlToElement } from "@grrr/utils";

/**
 * Create a basic native share button.
 * Note: check for `native.share()` capabilities before appending.
 */
export const create = ({ link, title, description, label = "Share" }) => {
  return htmlToElement(`
    <button data-handler="nativeShareButton" data-share-title="${title}"
      data-share-text="${description}" data-share-url="${link}">${label}</button>
  `);
};

export const handler = (button, e) => {
  e.preventDefault();
  navigator.share({
    title: button.getAttribute("data-share-title"),
    text: button.getAttribute("data-share-text"),
    url: button.getAttribute("data-share-url"),
  });
};

import CountdownClock from "./countdown-clock";

const CLOCK_SELECTOR = ".js-clock";
const START_DATE_ATTRIBUTE = "data-start-date";

const LiveEventCountdown = ({ container, date }) => {
  return {
    init() {
      // Start the clock.
      const clockEl = container.querySelector(CLOCK_SELECTOR);
      const countdownClock = CountdownClock(clockEl, date);
      countdownClock.init();
    },
  };
};

export const enhancer = (container) => {
  const startDateString = container.getAttribute(START_DATE_ATTRIBUTE);
  if (!startDateString) {
    return;
  }
  const date = Date.parse(startDateString);
  const liveCountdown = LiveEventCountdown({ container, date });
  liveCountdown.init();
};

const handleClick = (trigger, target) => () => {
  const newState =
    target.getAttribute("data-expanded") === "true" ? "false" : "true";

  target.setAttribute("data-expanded", newState);
  trigger.setAttribute("aria-pressed", newState);
  trigger.setAttribute("aria-expanded", newState);
};

export const enhancer = (trigger) => {
  const targetSelector = trigger.getAttribute("data-target-selector");
  const target = document.querySelector(targetSelector);

  if (!target) {
    return;
  }

  trigger.addEventListener("click", handleClick(trigger, target));
};

import { publish, subscribe } from "../observer";
import {
  updateMarkerInfo,
  updateClusterInfo,
  citizenScienceSideBarShowSingle,
} from "../observer-subjects";

const handleSurveyClick = (survey) => (event) => {
  publish(citizenScienceSideBarShowSingle, {});
  publish(updateMarkerInfo, {
    ...survey.properties,
    results: JSON.stringify(survey.properties.results),
    hideBackButton: false,
  });
};

/**
 * Called by observer.
 * Update the content of the cluster info based on data.
 */
const updateContent = (template, targetList, total) => (data) => {
  targetList.innerHTML = null;
  total.innerHTML = data.length;

  data.forEach((survey) => {
    const newListElement = template.content.cloneNode(true);

    const id = newListElement.querySelector(".js-id");
    const date = newListElement.querySelector(".js-date");
    const trigger = newListElement.querySelector(".js-trigger");

    id.innerHTML = survey.properties.displayId;
    date.innerHTML = survey.properties.dateTime;
    date.setAttribute("datetime", survey.properties.utcDate);
    trigger.addEventListener("click", handleSurveyClick(survey));

    targetList.appendChild(newListElement);
  });
};

export const enhancer = (element) => {
  const template = element.querySelector(".js-template");
  const targetList = element.querySelector(".js-list");
  const total = element.querySelector(".js-total");

  subscribe(updateClusterInfo, updateContent(template, targetList, total));
};

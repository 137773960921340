import Flickity from "flickity-imagesloaded";
import screenfull from "screenfull";
import { map } from "./util";
import { getDocWidth, matchesBreakpoint } from "./responsive";

const CARD_CLASS = "js-slider-card";
const MODAL_CLASS = "js-slider-card-modal";

const SLIDER_SELECTOR = ".js-slider";
const NAV_BUTTON_SELECTOR = ".js-slider-nav-button";
const CARD_SELECTOR = `.${CARD_CLASS}`;

const findNavButtons = (el) => [
  ...el.parentNode.querySelectorAll(NAV_BUTTON_SELECTOR),
];
const isCard = (el) => el.classList.contains(CARD_CLASS);
const isMilestone = (el) => el.classList.contains("is-milestone");

const goToLast = (flkty) => flkty.select(flkty.cells.length - 1);
const goToFirst = (flkty) => flkty.select(0);
const goToPrevious = (flkty) => {
  if (flkty.selectedIndex <= 0) {
    return;
  }
  flkty.previous();
};
const goToNext = (flkty) => {
  if (flkty.selectedIndex >= flkty.cells.length - 1) {
    return;
  }
  flkty.next();
};

const navigateToSlide = (button, flkty) => {
  const type = button.getAttribute("data-type");
  if (type === "first") {
    goToFirst(flkty);
  }
  if (type === "last") {
    goToLast(flkty);
  }
  window.setTimeout((e) => button.blur(), 0);
};

const getModal = () => document.body.querySelector(`.${MODAL_CLASS}`);

const removeModal = (modal) => {
  modal.parentNode.removeChild(modal);
  document.body.classList.remove("page-has-modal");
};

const appendModal = (modal) => {
  // Body alterations
  document.body.appendChild(modal);
  document.body.classList.add("page-has-modal");
  modal.classList.add("card--modal", "card--large", "is-faded", MODAL_CLASS);

  // a11y tweaks
  const closeButton = modal.querySelector(".js-close");
  closeButton.focus();
  window.setTimeout((e) => closeButton.blur(), 0);

  // fade it in
  window.setTimeout((e) => modal.classList.remove("is-faded"), 60);
};

/**
 * Replace small card image with larger version
 */
const replaceImage = (currentSlide, container) => {
  const slideImage = currentSlide.querySelector("img");
  const newImage = slideImage.getAttribute("data-large-image");
  const modalImage = container.querySelector("img");
  modalImage.setAttribute("src", newImage);
  modalImage.setAttribute("sizes", "(min-width: 550px) 550px, 80vw");
};

const openInModal = (cellElement, flkty) => {
  if (getModal()) {
    return;
  }

  // copy article content and clone + append, as modal
  const slideContent = cellElement.querySelector("article");
  const slideCopy = slideContent.cloneNode(true);

  // create modal content and launch it
  replaceImage(cellElement, slideCopy);
  appendModal(slideCopy);

  // scroll to selected  slide
  flkty.select(flkty.cells.findIndex((cell) => cell.element === cellElement));
};

/**
 * Copy selected slide content in existing modal
 */
const attachSlideSelectListener = (el, flkty) => {
  let currentIndex = 0;
  flkty.on("select", () => {
    const previousIndex = currentIndex;
    currentIndex = flkty.selectedIndex;
    // When viewing a video fullscreen, Flickity triggers a re-select due to resizing.
    // See: https://github.com/metafizzy/flickity/issues/529
    if (screenfull.isFullscreen || previousIndex === currentIndex) {
      return;
    }
    const { selectedElement } = flkty;
    if (!isMilestone(selectedElement) && !isCard(selectedElement)) {
      if (previousIndex < currentIndex) {
        flkty.next();
      } else {
        flkty.previous();
      }
    }
    const selectedSlide = el.querySelector(`${CARD_SELECTOR}.is-selected`);
    if (!selectedSlide) {
      return;
    }

    const selectedSlideClasses = selectedSlide
      .querySelector(".card")
      .getAttribute("class");
    const selectedSlideContent = selectedSlide.querySelector(".card").innerHTML;
    const cardModal = getModal();

    if (cardModal) {
      // cardModal is dynamically created
      cardModal.setAttribute(
        "class",
        `card--modal card--large ${MODAL_CLASS} ${selectedSlideClasses}`
      );
      cardModal.innerHTML = selectedSlideContent;
      replaceImage(selectedSlide, cardModal);
    }
  });
};

/**
 * Open modal on item click
 */
const attachSlideClickListener = (el, flkty) => {
  // use Flkty's 'staticClick' to prevent click-trigger after 'dragging'
  flkty.on("staticClick", (e, pointer, cellElement, cellIndex) => {
    if (!cellElement || !isCard(cellElement)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    window.setTimeout(() => openInModal(cellElement, flkty), 0);
  });

  // click handler for items inside cards, so we can tab/click to launch the modal
  const innerClickHandler = (e) => {
    const tagName = e.target.tagName.toUpperCase();
    if (tagName !== "BUTTON" && tagName !== "A") {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    /**
     * @TODO `cellElement` is not defined...
     */
    // window.setTimeout(() => openInModal(cellElement, flkty), 0);
  };

  const cards = [...el.querySelectorAll(CARD_SELECTOR)];
  cards.map((card) => card.addEventListener("click", innerClickHandler));
};

const addNavClickListener = (buttons, flkty) => (button) => {
  button.addEventListener("click", (e) => {
    navigateToSlide(button, flkty);
  });
};

const attachKeyListeners = (flkty) => {
  document.body.addEventListener("keyup", (e) => {
    if ((e.key && e.key === "Escape") || e.keyCode === 27) {
      if (screenfull.isFullscreen) {
        return;
      }
      removeModal(getModal());
    }
    if ((e.key && e.key === "Left") || e.keyCode === 37) {
      goToPrevious(flkty);
    }
    if ((e.key && e.key === "Right") || e.keyCode === 39) {
      goToNext(flkty);
    }
  });
};

const calculateInitialSlide = (slider) => {
  const cells = slider.querySelectorAll("li");
  const cards = slider.querySelectorAll(CARD_SELECTOR);
  if (!matchesBreakpoint("small")) {
    return [...cells].findIndex((cell) => cell === cards[cards.length - 1]);
  }
  return cells.length - 1;
};

export const enhancer = (el) => {
  const slider = el.querySelector(SLIDER_SELECTOR);
  const type = el.getAttribute("data-type");

  const flkty = new Flickity(slider, {
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 70,
      y2: 40,
      x3: 30,
    },
    // The `timeline` is aligned at the last post (right), and the `simple`
    // variation (limited to 4 posts) is aligned left.
    initialIndex: type === "timeline" ? calculateInitialSlide(slider) : 0,
    imagesLoaded: true,
    autoPlay: false,
    freeScroll: true,
    cellAlign: "center",
    cellSelector: "li",
    contain: true,
    pageDots: false,
    // We handle this ourselves.
    accessibility: false,
    // The `simple` variation shouldn't be draggable on 'desktop'.
    draggable: type === "timeline" || getDocWidth() < 1160,
  });

  const navButtons = findNavButtons(el);
  map(navButtons, addNavClickListener(navButtons, flkty));

  attachSlideClickListener(el, flkty);
  attachSlideSelectListener(el, flkty);
  attachKeyListeners(flkty);

  // Sort of fix horizontal/vertical scroll fuckery on iOS.
  // https://github.com/metafizzy/flickity/issues/457#issuecomment-254501356
  window.addEventListener("touchmove", (e) => {});
};

export const handler = (el, e) => {
  e.preventDefault();
  removeModal(getModal());
};

import { waitForGlobal } from "../util";
import { publish } from "../observer";
import { trashTrackerSetLatLong } from "../observer-subjects";

/**
 * Handle user selection.
 */
const onPlaceChanged = (element, autocomplete, google) => () => {
  // Use geocoder to retrieve geometry. We avoid calling autocomplete.getPlace
  // because Geocoder is a cheaper service.
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: element.value }, (results, status) => {
    if (status === "OK" && results.length) {
      publish(trashTrackerSetLatLong, {
        lat: results[0].geometry.location.lat(),
        long: results[0].geometry.location.lng(),
        placename: element.value,
      });
    } else {
      element.placeholder = "Enter a location";
    }
  });

  /**
   * This is the old way: using autocomplete.getPlace() in the callback.
   * The Ocean Cleanup reported high costs after launch because of the
   * use of the Autocomplete API with "detailed results".
   *
   * See also https://projects.grrr.nl/projects/182/tasks/42042
   *
  // Get place based on Google generated list.
  const place = autocomplete.getPlace();

  if (!place.geometry) {
    console.warn("Warning: Place does not contain geometry.");
    element.placeholder = "Enter a location";
    return;
  }

  // Publish lat/long.
  publish(trashTrackerSetLatLong, {
    lat: place.geometry.location.lat(),
    long: place.geometry.location.lng(),
    placename: place.name,
  });
  */
};

/**
 * Initialize Google Autocomplete.
 */
const initializeAutocomplete = (element) => (google) => {
  // Create autocomplete
  const autocomplete = new google.maps.places.Autocomplete(element, {
    fields: ["place_id"], // ["geometry", "name"],
  });

  // Add update listener
  autocomplete.addListener(
    "place_changed",
    onPlaceChanged(element, autocomplete, google)
  );
};

/**
 * Initialize handler when Google is loaded.
 */
export const enhancer = (element) => {
  waitForGlobal("google", 100).then(initializeAutocomplete(element));
};

import { closest, dispatchEvent, htmlToElement } from "./util";
import checkForTypo from "./email-validation";

const EMAIL_SUGGESTION_CLASS = "js-email-suggestion";

const addEmailSuggestion = (emailElement, suggestedEmail) => {
  const suggestion = htmlToElement(`
    <p class="form-suggestion ${EMAIL_SUGGESTION_CLASS}" role="alert">
      Did you mean <em>${suggestedEmail}?</em>
      <button class="form-suggestion__accept" type="button" data-handler="emailSuggestionsUse" data-suggestion="${suggestedEmail}">Use suggestion</button>
      <button class="form-suggestion__reject" type="button" data-handler="emailSuggestionsReject" aria-label="Reject and remove suggestion">
        <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
          <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="2">
            <path d="M13.607 2.54L2.335 13.81M13.834 14.098L2.049 2.313"/>
          </g>
        </svg>
      </button>
    </p>
  `);
  emailElement.parentNode.insertBefore(
    suggestion,
    emailElement.nextElementSibling
  );
  suggestion.querySelector("button").focus();
};

const removeEmailSuggestion = (emailElement) => {
  const suggestion = emailElement.parentNode.querySelector(
    `.${EMAIL_SUGGESTION_CLASS}`
  );
  if (!suggestion) {
    return;
  }
  emailElement.parentNode.removeChild(suggestion);
};

const removeSuggestionContainer = (button) => {
  const suggestionContainer = closest(button, `.${EMAIL_SUGGESTION_CLASS}`);
  suggestionContainer.parentNode.removeChild(suggestionContainer);
};

/**
 * Click handler for "Use suggestion" button
 */
export const useSuggestionHandler = (button) => {
  const suggestedEmail = button.getAttribute("data-suggestion");
  const form = closest(button, "form");
  removeSuggestionContainer(button);
  form.elements.email.value = suggestedEmail;
  form.elements.email.focus();
  dispatchEvent(form.elements.email, ["change", "input"]);
};

/**
 * Rejection handler for "Discard suggestion" button
 */
export const rejectSuggestionHandler = removeSuggestionContainer;

/**
 * Listen for changes on input to provide user with suggestions, fixing small typos in
 * otherwise valid email strings. Example: "harmen@gmail" will suggest "harmen@gmail.com".
 */
export const enhancer = (input) => {
  input.addEventListener("change", (e) => {
    const suggestedEmail = checkForTypo(input.value || "");
    removeEmailSuggestion(input);
    if (suggestedEmail) {
      addEmailSuggestion(input, suggestedEmail);
    }
  });
};

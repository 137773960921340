import { debounce } from "./util";

/**
 * Responsive breakpoint registry
 */

let docWidth;
let docHeight;

const BREAKPOINT_SMALL = 540;
const BREAKPOINT_MEDIUM = 720;
const BREAKPOINT_MEDIUM_LARGE = 900;
const BREAKPOINT_LARGE = 1020;
const BREAKPOINT_EXTRA_LARGE = 1400;
const BREAKPOINT_HUGE = 1800;

const setDocWidth = () => {
  docWidth = window.innerWidth || document.documentElement.clientWidth;
};

export const getDocWidth = () => {
  if (!docWidth) {
    setDocWidth();
  }
  return docWidth;
};

const setDocHeight = () => {
  docHeight = window.innerHeight || document.documentElement.clientHeight;
};

export const getDocHeight = () => {
  if (!docHeight) {
    setDocHeight();
  }
  return docHeight;
};

export const matchesBreakpoint = (breakpoint) => {
  switch (breakpoint) {
    case "small":
      return getDocWidth() >= BREAKPOINT_SMALL;
    case "medium":
      return getDocWidth() >= BREAKPOINT_MEDIUM;
    case "mediumLarge":
      return getDocWidth() >= BREAKPOINT_MEDIUM_LARGE;
    case "large":
      return getDocWidth() >= BREAKPOINT_LARGE;
    case "extraLarge":
      return getDocWidth() >= BREAKPOINT_EXTRA_LARGE;
    case "huge":
      return getDocWidth() >= BREAKPOINT_HUGE;
    default:
      return false;
  }
};

export const getCurrentBreakpoint = () => {
  const breakpoints = ["small", "medium", "large", "extraLarge", "huge"];
  const matches = breakpoints.filter(matchesBreakpoint);
  return matches[matches.length - 1];
};

export default () => {
  window.addEventListener("resize", debounce(setDocWidth, 300));
  window.addEventListener("orientationchange", debounce(setDocWidth, 300));
};

/**
 * Check if the resize event was thrown by mobile browser header collapsing during scroll
 */
export const isMobileHeaderResize = (previousDocHeight, previousDocWidth) => {
  const threshold = 80;
  const currentDocumentHeight = getDocHeight();
  const currentDocumentWidth = getDocWidth();

  const heightDifference = Math.abs(previousDocHeight - currentDocumentHeight);
  const widthDifference = Math.abs(previousDocWidth - currentDocumentWidth);

  // in case anything changes with the width, return false
  if (widthDifference > 0) {
    return false;
  }

  if (heightDifference === 0) {
    // if the height difference is 0 return true
    return true;
  }
  if (heightDifference < threshold) {
    // if the height difference is below the threshold
    return true;
  }

  return false;
};

import { MapIntro, SELECTOR as MAP_INTRO_SELECTOR } from "./map-intro";

const COUNTER_SELECTOR = ".js-counter";

export const SourcesMapOverlays = (container) => {
  let hasInteracted = false;

  const mapIntro = new MapIntro(container.querySelector(MAP_INTRO_SELECTOR));

  /**
   * Handle corner toggles, with optional action (open or close).
   */
  const toggleCornerOverlay = (id, action = null) => {
    const button = container.querySelector(
      `.js-corner-toggle[aria-controls=${id}]`
    );
    const overlay = container.querySelector(`.js-corner-overlay#${id}`);
    const isExpanded = button.getAttribute("aria-expanded") === "true";
    const shouldClose = action ? action === "close" : isExpanded;
    button.setAttribute("aria-expanded", !shouldClose);
    overlay.setAttribute("aria-hidden", !!shouldClose);
    button.blur();
    hasInteracted = true;
  };

  const closeAllOverlays = (e) => {
    mapIntro.hide();
    toggleCornerOverlay("map-instructions", "close");
    toggleCornerOverlay("map-legend", "close");
    hasInteracted = true;
  };

  const handleFirstClick = (e) => {
    if (hasInteracted) {
      return;
    }
    hasInteracted = true;
    mapIntro.hide();
    window.setTimeout(
      () => toggleCornerOverlay("map-instructions", "open"),
      150
    );
    window.setTimeout(() => toggleCornerOverlay("map-legend", "open"), 300);
  };

  /**
   * Handle corner toggles, with optional action (open or close).
   */
  const attachCornerToggleListeners = () => {
    const buttons = container.querySelectorAll(".js-corner-toggle");
    [...buttons].forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        const controls = button.getAttribute("aria-controls");
        toggleCornerOverlay(controls);
      });
    });
  };

  /**
   * Update the counter.
   */
  const updateCounter = (counter) => {
    const increment = parseFloat(counter.textContent);
    let count = 0;
    window.setInterval((e) => {
      count += increment;
      if (typeof Intl === "object" && typeof Intl.NumberFormat === "function") {
        counter.textContent = Number(count).toLocaleString("en", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      } else {
        counter.textContent = parseFloat(count).toFixed(2);
      }
    }, 1000);
  };

  return {
    init() {
      attachCornerToggleListeners();
      updateCounter(container.querySelector(COUNTER_SELECTOR));
      mapIntro.init();
      mapIntro.on("hide", handleFirstClick);
    },
    hasInteracted: () => hasInteracted,
    handleFirstClick,
    closeAllOverlays,
  };
};

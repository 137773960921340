/**
 * Round and format number for Dashboards.
 */
export default (number) => {
  if (Intl.NumberFormat) {
    return new Intl.NumberFormat().format(Math.round(number));
  }

  return number;
};

import { subscribe } from "./observer";
import { systemsDashboardReceivedData } from "./observer-subjects";
import formatDashboardNumber from "../utils/format-dashboard-number";

/**
 * Get unique objects from Array.
 * Code from: https://www.codegrepper.com/code-examples/javascript/get+unique+array+of+objects+javascript.
 */
const arrayUniqueByKey = (aa, key) => [
  ...new Map(aa.map((item) => [item[key], item])).values(),
];

/**
 * Update contents of the totals based on the received realtime data.
 */
const updateTotals = (elements, totals) => {
  if (elements.lastDays) {
    elements.lastDays.innerHTML = formatDashboardNumber(
      totals.debris_extracted_last_30d
    );
  }
  if (elements.total) {
    elements.total.innerHTML = formatDashboardNumber(
      totals.debris_extracted_total
    );
  }
};

/**
 * Sort the items by status.
 * The status is derived from the interceptors, and the order of them in the API isn't "sorted-by-status".
 */
const sortByStatus = (first, second) => {
  const preferredOrder = [
    // River statuses
    "in_operation",
    "installed_for_testing",
    "build_started",
    "build_completed",
    "contract_signed",
    "planned",
    "in_maintenance",

    // Ocean statuses
    "active",
    "deployment",
    "extraction",
    "harvesting",
    "port_call",
    "port call / mobilisation",
    "transit",
    "recovery",
    "standby",
    "technical_downtime",
    "weather_downtime",
    "operation_paused",
  ];

  if (
    preferredOrder.indexOf(first.status) < preferredOrder.indexOf(second.status)
  ) {
    return -1;
  }
  if (
    preferredOrder.indexOf(first.status) > preferredOrder.indexOf(second.status)
  ) {
    return 1;
  }
  return 0;
};

/**
 * Ensure the Ocean systems are always at the bottom of the list.
 */
const sortByInterceptorType = (a, b) =>
  b.systemType === "Ocean System" ? -1 : 0;

/**
 * Create a HTML status element based on the template.
 */
const createStatusElement = (elements, item) => {
  const { status, riverCount, oceanCount, label } = item;
  // Create clone of template.
  const clone = elements.interceptorsTemplate.content.cloneNode(true);

  // Get all relevant HTML element.
  const statusElement = clone.querySelector(".js-status");
  const interceptorsElement = clone.querySelector(".js-interceptors");
  const oceansElement = clone.querySelector(".js-oceans");

  // Update the content values.
  statusElement.innerHTML = label;
  statusElement.setAttribute("data-status", status);

  const interceptorsLabel = riverCount > 1 ? "Interceptors" : "Interceptor";
  interceptorsElement.innerHTML = `${riverCount} ${interceptorsLabel}`;
  interceptorsElement.setAttribute("data-visible", !!riverCount);

  const oceanSystemsLabel = oceanCount > 1 ? "Ocean systems" : "Ocean system";
  oceansElement.innerHTML = `${oceanCount} ${oceanSystemsLabel}`;
  oceansElement.setAttribute("data-visible", !!oceanCount);

  // Return a status element with the correct data.
  return clone;
};

/**
 * Create a status element for each relevant interceptor status.
 */
const createStatusElements = (elements, systems) => {
  // Systems that don't show the status in the dashboard, should also not be used in this meta list.
  const relevantSystems = systems.filter((system) => system.show_status);

  // Get all unique statuses from all interceptors.
  const statuses = relevantSystems.map((system) => ({
    status: system.status || "status unknown", // Edge case: if an interceptor has no status.
    statusLabel: system.status_label || system.status,
    systemType: system.type,
  }));

  const uniqueStatuses = arrayUniqueByKey(statuses, "status");

  // Get specific systems
  const riverSystems = relevantSystems.filter(
    (system) => system.system_type === "River"
  );
  const oceanSystems = relevantSystems.filter(
    (system) => system.system_type === "Ocean"
  );

  // Create a HTML element for each unique status.
  uniqueStatuses
    .sort(sortByStatus)
    .sort(sortByInterceptorType)
    .map(({ status, statusLabel }) => {
      // Get matching systems.
      const matchingSystems = (specificSystems) =>
        specificSystems.filter((system) => {
          // Edge case if an interceptor has no status.
          return status === "status unknown"
            ? system.status === undefined
            : system.status === status;
        });

      // Merge River systems with additional locations to match the status count with the dots on the map.
      const matchingRiverSystems = matchingSystems(riverSystems).reduce(
        (acc, system) => [
          ...acc,
          system,
          ...(system.additional_locations || []),
        ],
        []
      );

      return {
        status,
        riverCount: matchingRiverSystems.length,
        oceanCount: matchingSystems(oceanSystems).length,
        label: statusLabel,
      };
    })
    .map((item) => createStatusElement(elements, item))
    .map((statusElement) => elements.statusesList.appendChild(statusElement));
};

/**
 * Called by Observer.
 * Update contents of HTML with the received data.
 */
const handleReceiveData = (elements) => ({ totals, systems }) => {
  updateTotals(elements, totals);
  createStatusElements(elements, systems);
};

export const enhancer = (container) => {
  const elements = {
    lastDays: container.querySelector(".js-last-days"),
    total: container.querySelector(".js-total"),
    interceptorsTemplate: container.querySelector(".js-interceptors-template"),
    statusesList: container.querySelector(".js-statuses-list"),
  };

  subscribe(systemsDashboardReceivedData, handleReceiveData(elements));
};

import { supportsIntersectionObserver } from "./util";

/**
 * Called by observer.
 * Set state of element based on the visibility of the entry element.
 */
const callback = (element) => (entries) => {
  entries.forEach((entry) => {
    element.setAttribute("data-visible", !entry.isIntersecting);
    element.setAttribute("aria-hidden", entry.isIntersecting);
  });
};

/**
 * Show the donate bar if the donate form isn't visible anymore.
 */
export const enhancer = (element) => {
  if (!supportsIntersectionObserver()) {
    return;
  }

  const options = {
    threshold: 0.1,
  };

  const trigger = document.querySelector(element.getAttribute("data-trigger"));
  const observer = new IntersectionObserver(callback(element), options);
  observer.observe(trigger);
};

import { throttle, last } from "@grrr/utils";
import cleanString from "../utils/clean-string";
import { publish, subscribe } from "./observer";
import {
  isVisibleOrScrolledPast,
  isScrolledPast,
  register,
} from "./scroll-listener";
import {
  updateInPageNavigationActiveItem,
  updateInPageNavigationState,
  closeInPageNavigation,
} from "./observer-subjects";

export const enhancer = (element) => {
  let isClosed = false;
  let isFixed = false;

  const scrollPastTrigger = element.querySelector(".js-scroll-past-trigger");
  const trigger = element.querySelector(".js-in-page-navigation-trigger");

  // Ensure all items that we track when scrolling are also in the in-page navigation.
  const inPageNavigationItems = Array.from(
    document.querySelectorAll(".js-in-page-navigation-item")
  ).map((heading) => cleanString(heading.innerText));
  const headings = Array.from(
    document.querySelectorAll(`main h2, main h3, main h4, main h5, main h6`)
  ).filter((heading) =>
    inPageNavigationItems.includes(cleanString(heading.innerText))
  );

  // Update UI based on state.
  const updateUi = () => {
    const uiStateIsChanged =
      element.getAttribute("data-state") !== (isFixed ? "fixed" : "static") ||
      element.getAttribute("data-closed-state") !==
        (isClosed ? "closed" : "open");

    // Prevent unnecessary DOM updates.
    if (!uiStateIsChanged) return;

    // Ensure the parent element has a height when the child becomes fixed with CSS.
    element.style.height = isFixed ? `${element.offsetHeight}px` : "";

    // Update data-attributes.
    element.setAttribute("data-state", isFixed ? "fixed" : "static");
    element.setAttribute("data-closed-state", isClosed ? "closed" : "open");

    publish(updateInPageNavigationState, { isClosed, isFixed });
  };

  const handleClose = () => {
    // Don't close when popup is fixed.
    if (!isFixed) return;

    isClosed = true;
    updateUi();
  };

  // Update UI based on scroll trigger.
  const elementScrollHandler = (e) => {
    if (isScrolledPast(scrollPastTrigger)) {
      // Close menu if element becomes fixed.
      if (!isFixed) isClosed = true;

      isFixed = true;
      updateUi();
    } else {
      // Close menu if element becomes static.
      if (isFixed) isClosed = false;

      isFixed = false;
      updateUi();
    }
  };

  // Notify the current heading through the observer.
  const headingsScrollHandler = (e) => {
    const lastScrolledPastHeading = last(
      headings.filter((heading) => isVisibleOrScrolledPast(heading))
    );

    if (!lastScrolledPastHeading) return;

    publish(updateInPageNavigationActiveItem, lastScrolledPastHeading);
  };

  const handleClick = (event) => {
    isClosed = !isClosed;
    updateUi();
  };

  trigger.addEventListener("click", handleClick);
  register(`element-sticky-observer`, throttle(elementScrollHandler, 100));
  register(`heading-sticky-observer`, throttle(headingsScrollHandler, 100));
  subscribe(closeInPageNavigation, handleClose);
};

import slugify from "./slugify";

/**
 * If the first character is -, remove the character.
 */
export const removeInitialDash = (string) =>
  string.charAt(0) === "-" ? string.substr(1) : string;

/**
 * Combine cleaning function for easier use.
 */
export default (string) => removeInitialDash(slugify(string));

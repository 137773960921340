/**
 * Handles the clicks on a media tab item, and show the pane
 */

const loadTabImages = (image, panel) => {
  if (image.getAttribute("data-src")) {
    image.setAttribute("src", image.getAttribute("data-src"));
    image.setAttribute("srcset", image.getAttribute("data-srcset"));
  }
  const fixedPanelImage = panel.querySelector("img[data-srcset]");
  if (fixedPanelImage) {
    fixedPanelImage.setAttribute(
      "src",
      fixedPanelImage.getAttribute("data-src")
    );
    fixedPanelImage.setAttribute(
      "srcset",
      fixedPanelImage.getAttribute("data-srcset")
    );
  }
};

export const handler = (el, e) => {
  e.preventDefault();

  const tab =
    e.target.tagName.toLowerCase() === "a" ? e.target : e.target.parentNode;
  const target = tab.getAttribute("aria-controls");
  if (!target) {
    return;
  }

  const oldTab = el.querySelector('[aria-selected="true"]');
  const oldPanel = el.querySelector('div[aria-hidden="false"]');
  const oldImage = el.querySelector('img[aria-hidden="false"]');

  oldTab.setAttribute("aria-selected", false);
  oldPanel.setAttribute("aria-hidden", true);
  oldImage.setAttribute("aria-hidden", true);

  const panel = el.querySelector(`#${target}`);
  const image = el.querySelector(`[data-panel-id="${target}"]`);

  loadTabImages(image, panel);

  tab.setAttribute("aria-selected", true);
  panel.setAttribute("aria-hidden", false);
  image.setAttribute("aria-hidden", false);
};

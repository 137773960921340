import Flickity from "flickity";
import { debounce } from "@grrr/utils";
import { getDocWidth } from "../responsive";
import { subscribe } from "../observer";
import { sunglassesStoriesReceivedData } from "../observer-subjects";
import { getStoriesToAdd, getPrioId } from "./sunglasses-utils";

let flkty = null;

const flktyOptions = {
  autoPlay: false,
  cellAlign: "left",
  contain: true,
  freeScroll: false,
  pageDots: false,
  prevNextButtons: true,
  accessibility: false,
  cellSelector: ".js-sunglasses-story",
  arrowShape: {
    x0: 20,
    x1: 65,
    y1: 45,
    x2: 70,
    y2: 40,
    x3: 30,
  },
};

const resetSliderBounds = (slider) => {
  slider.style.width = ``;
  slider.style.marginLeft = ``;
  slider.style.paddingLeft = ``;
};

/**
 * Update bounds because of slider offset.
 * It's glued to the right side of the screen, with a lef margin, but needs to
 * have arrows glued to the edges of the screen.
 */
const updateSliderBounds = (slider) => {
  const bounds = slider.getBoundingClientRect();
  const rightOffset = getDocWidth() - bounds.right;
  slider.style.width = `${bounds.width + rightOffset}px`;

  slider.style.width = `${getDocWidth()}px`;
  slider.style.marginLeft = `-${rightOffset}px`;
  slider.style.paddingLeft = `${rightOffset}px`;
};

const handleScroll = (next) => (event) => {
  const last = flkty.getLastCell();
  if (last.element.getBoundingClientRect().right < getDocWidth()) {
    next.disabled = true;
  } else {
    next.disabled = false;
  }
};

/**
 * Called by the observer.
 *
 * Add stories to the DOM based on the received data.
 */
const handleDataUpdate = (element, template, maxResults, prioId) => ({
  stories,
}) => {
  // Empty current DOM
  const currentElements = Array.from(element.querySelectorAll("li"));
  flkty.remove(currentElements);

  // Optionally limit the amount of stories to show.
  const storiesToAdd = getStoriesToAdd(stories, maxResults, prioId);

  // Add stories to the DOM
  const newListElements = storiesToAdd.map((story) => {
    // Clone template to be able to re-use the HTML.
    const clone = template.content.cloneNode(true);

    // Get template elements and fill with correct content.
    const editElement = clone.querySelector('[data-element="edit"]');
    const aliasElement = clone.querySelector('[data-element="alias"]');
    const storyElement = clone.querySelector('[data-element="story"]');
    const containerElement = clone.querySelector('[data-element="container"]');
    aliasElement.innerHTML = story.attributes.alias;
    storyElement.innerHTML = story.attributes.story;

    if (!story.isHighlighted) {
      editElement.remove();
    } else {
      containerElement.setAttribute("data-highlighted", "true");
    }

    // Return firstElementChild to return a Node, not a DocumentElement (which clone is)
    // because we cannot append a DocumentElement.
    return clone.firstElementChild;
  });

  // Set new elements.
  // Use append to prevent the slider from staring at the end.
  flkty.append(newListElements, 0);
  // Set slider to the first slide (to prevent the slider being further than there are new cards available).
  flkty.select(0);
};

const selectMatchingStory = (element) => {
  const slider = document.querySelector(".sunglasses-stories-block__list");
  const currentAlias = element.innerText;
  const sliderStories = slider.querySelectorAll(".js-sunglasses-story");
  Array.from(sliderStories).map((sliderStory, index) => {
    const alias = sliderStory.querySelector('[data-element="alias"]').innerText;
    if (currentAlias === alias) {
      flkty.select(index - 1);
    }
  });
};

export const handler = (element, e) => {
  e.preventDefault();
  selectMatchingStory(element);
};

export const enhancer = (element) => {
  const template = element.querySelector("template");
  const maxResults = Number(element.getAttribute("data-max-results"));
  const slider = element.querySelector("ul");

  updateSliderBounds(slider);

  flkty = new Flickity(slider, flktyOptions);

  // Quick and dirty fix to disable the 'next' button when the last slide is
  // visible. Due to accessibility the slider allows to go to the last slide,
  // while that is already visible for a while on desktop screens.
  // All slides are focusable so we disable keyboard controls for the slider itself.
  const next = element.querySelector(".flickity-prev-next-button.next");
  flkty.on("scroll", handleScroll(next));

  window.addEventListener(
    "resize",
    debounce(() => {
      resetSliderBounds(slider);
      updateSliderBounds(slider);
      flkty.resize();
    }, 500)
  );

  subscribe(
    sunglassesStoriesReceivedData,
    handleDataUpdate(element, template, maxResults, getPrioId())
  );
};

import {
  debounce,
  supportsIntersectionObserver,
  prefersNoMotion,
} from "./util";
import { matchesBreakpoint, isMobileHeaderResize } from "./responsive";
import { publish } from "./observer";

const triggers = {
  allTriggers: [],
  indexes: {},
};
let observer = null;
const dimensions = {
  windowWidth: null,
  windowHeight: null,
};

const resetDimensionsValues = () => {
  dimensions.windowHeight = window.innerHeight;
  dimensions.windowWidth = window.innerWidth;
};

/**
 * Generate a list of indexed accessible by id.
 * Also uses a default to handle the "total" card.
 */
const generateIndexes = (allTriggers) => {
  const indexes = allTriggers.reduce((allItems, triggerElement, index) => {
    const id = triggerElement.getAttribute("data-twin-id");
    return {
      ...allItems,
      [id]: index,
    };
  }, {});

  return {
    ...indexes,
    total: 0,
  };
};

/**
 * Triggers on window resize by the handleWindowResize function.
 * Makes the old observer obsolete.
 */
const removeOldObserver = () => {
  if (!observer) {
    return;
  }
  triggers.allTriggers.forEach((trigger) => {
    observer.unobserve(trigger);
  });
};

/**
 * Creates a store(object) for all elements and transition values needed for the observer to zoom.
 */

const handleIntersect = (entries) => {
  entries.forEach((entry) => {
    const activeId = entry.target.getAttribute("data-twin-id");

    if (entry.intersectionRatio >= 0.5) {
      publish("plastic-journey-active-card", {
        id: activeId,
        index: triggers.indexes[activeId],
      });
    }
  });
};

/**
 * Calculates the margin for the observer.
 * The margin is different for each screen-size.
 */
const calculateObserverMargin = () => {
  const windowWidth = window.innerWidth;
  const cardWidth = triggers.allTriggers[0].getBoundingClientRect().width;
  // Based on the CSS value of 2.5vw.
  const cardMargin = (windowWidth / 100) * 2.5;
  const cardWidthIncludingMargins = cardWidth + cardMargin * 2;

  return Math.round((windowWidth - cardWidthIncludingMargins) / 2);
};

/**
 * Create the observer with the correct options.
 */
const createObserver = (root) => {
  // The margin is used to decrease the horizontal scope(in size) of the observer.
  // This creates a smaller scope in which the observer will observe the visibility of the item(%).
  // This solves the problem for larger screens where there are multiple cards visible.
  const margin = calculateObserverMargin();
  // Only use the margin on the horizontal axes and use a - to decrease instead of increase.
  const rootMargin = `0px -${margin}px 0px -${margin}px`;

  // Trigger the interactionHandler function when the element visibility changes to:
  // 0% in scope, 40% in scope, 50% in scope, 60% in scope, 100% in scope.
  const threshold = [0, 0.5, 1];

  const options = {
    root,
    threshold,
    rootMargin,
  };

  return new IntersectionObserver(handleIntersect, options);
};

/**
 * Called on window resize.
 * Recalculate the transform values and observer options each time the window resizes.
 */
const handleWindowResize = (allTriggers, rootElement) => (event) => {
  if (isMobileHeaderResize(dimensions.windowHeight, dimensions.windowWidth)) {
    resetDimensionsValues();
    return;
  }
  resetDimensionsValues();

  // Remove old observer
  removeOldObserver();

  // Bail out if the screen is to large.
  if (matchesBreakpoint("mediumLarge")) {
    return;
  }

  // Create new observer
  observer = createObserver(rootElement);

  // Observe elements with new observer
  allTriggers.forEach((trigger) => {
    observer.observe(trigger);
  });
};

/**
 * Observes the center card and notifies this through an observer.
 */
export const enhancer = (element) => {
  if (!supportsIntersectionObserver() || prefersNoMotion) {
    return;
  }

  const triggerSelector = element.getAttribute(
    "data-center-card-observer-trigger"
  );

  triggers.allTriggers = Array.from(element.querySelectorAll(triggerSelector));
  triggers.indexes = generateIndexes(triggers.allTriggers);

  const resize = handleWindowResize(triggers.allTriggers, element);

  resize();
  window.addEventListener("resize", debounce(resize, 500));
  window.addEventListener("orientationchange", debounce(resize, 500));
};

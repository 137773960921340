export const enhancer = (container) => {
  const triggers = Array.from(
    container.querySelectorAll('[data-element="map-scroller-trigger"]')
  );
  const videos = Array.from(
    container.querySelectorAll(`[data-element="map-scroller-target"]`)
  );

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const elementIndex = entry.target.getAttribute("data-index");
        if (!elementIndex) return;

        videos.forEach((video, index) => {
          video.setAttribute(
            "data-play",
            Number(elementIndex) === index + 1 ? "true" : "false"
          );
        });
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, {
    threshold: 0.5,
  });
  triggers.forEach((trigger) => {
    observer.observe(trigger);
  });

  // This event is called when the video is not allowed to play.
  // Main reason: Device is on low power mode.
  videos.forEach((video) => {
    video.addEventListener("suspend", () => {
      const body = document.querySelector("body");

      // Play video when it's not already playing.
      const handleUserInteraction = () => {
        if (!video.playing) {
          video.play();
        }

        body.removeEventListener("click", handleUserInteraction);
        body.removeEventListener("touchstart", handleUserInteraction);
      };

      // Little workaround to play auto-playing video's when the user interacts with the page.
      // https://shaktisinghcheema.com/how-to-autoplay-video-on-mobile-devices-on-low-power-mode/
      //
      // Normally you would just use the browsers play button, but for this module,
      // the video's are stacked and the visible video doesn't always have the correct stacking order.
      body.addEventListener("click", handleUserInteraction);
      body.addEventListener("touchstart", handleUserInteraction);
    });
  });
};

import { forEach, next, selfOrClosest } from "./util";

export const enhancer = (el) => {
  const logos = el.querySelectorAll(".js-open");
  if (!logos.length) {
    return;
  }

  let hasActive = false;

  const hideMetas = () => {
    forEach(logos, (logo, index) => {
      const meta = next(logo);
      logo.setAttribute("aria-expanded", "false");
      meta.setAttribute("aria-hidden", "true");
    });
    hasActive = false;
  };

  const showMeta = (logo) => {
    hideMetas();
    const meta = next(logo);
    logo.setAttribute("aria-expanded", "true");
    meta.setAttribute("aria-hidden", "false");
    hasActive = true;
  };

  // Attach listeners to logos
  forEach(logos, (logo, index) => {
    logo.addEventListener("click", (e) => {
      e.preventDefault();
      if (logo.getAttribute("aria-expanded") !== "true") {
        showMeta(logo);
      } else {
        hideMetas();
      }
      logo.blur();
    });
  });

  // Attach listeners to close button
  const closes = el.querySelectorAll(".js-close");
  forEach(closes, (close, index) => {
    close.addEventListener("click", (e) => {
      e.preventDefault();
      hideMetas();
    });
  });

  // Attach listener to body for closing when element is open
  document.body.addEventListener("click", (e) => {
    if (
      selfOrClosest(e.target, ".js-open") ||
      selfOrClosest(e.target, ".js-meta")
    ) {
      return;
    }
    if (hasActive) {
      hideMetas();
    }
  });
};

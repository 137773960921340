const CLOCK_PART_ATTRIBUTE = "data-clock-part";

const CountdownClock = (container, timestamp) => {
  let interval;

  const clockParts = {
    days: container.querySelector(`[${CLOCK_PART_ATTRIBUTE}="days"]`),
    hours: container.querySelector(`[${CLOCK_PART_ATTRIBUTE}="hours"]`),
    minutes: container.querySelector(`[${CLOCK_PART_ATTRIBUTE}="minutes"]`),
    seconds: container.querySelector(`[${CLOCK_PART_ATTRIBUTE}="seconds"]`),
  };

  const getTimeRemaining = () => {
    const time = timestamp - Date.now();

    // Format d, h, m, s and round numbers.
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    return {
      total: time,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const updateClock = () => {
    const time = getTimeRemaining();

    // Update all clock parts.
    Object.entries(clockParts).forEach(([key, part]) => {
      part.textContent = time.total >= 0 ? `0${time[key]}`.slice(-2) : "00";
    });

    // Stop counting when deadline is reached.
    if (time.total <= 0) {
      clearInterval(interval);
    }
  };

  return {
    init() {
      updateClock();
      interval = setInterval((e) => {
        updateClock();
      }, 1000);
    },
  };
};

export default CountdownClock;

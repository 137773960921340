import { dispatchEvent } from "./util";
import getUserLocation from "../utils/get-user-location";

const EUCountryCodes = [
  "AD", // Andorra
  "BE", // België
  "CY", // Cyprus
  "EE", // Estland
  "DE", // Duitsland
  "FI", // Finland
  "FR", // Frankrijk
  "GR", // Griekenland
  "IE", // Ierland
  "IT", // Italië
  "LV", // Letland
  "LT", // Litouwen
  "LU", // Luxemburg
  "MT", // Malta
  "MC", // Monaco
  "NL", // Nederland
  "AT", // Oostenrijk
  "PT", // Portugal
  "SM", // San Marino
  "SI", // Slovenië
  "SK", // Slowakije
  "ES", // Spanje
  "VA", // Vaticaanstad
];

/**
 * Preselect currency input based on user location.
 */
export const enhancer = async (element) => {
  const url = element.getAttribute("data-api-endpoint");

  const euroInput = element.querySelector('[value="EUR"]');
  const dollarInput = element.querySelector('[value="USD"]');

  const userLocation = await getUserLocation(url);

  // Bail out if no user location can be determined.
  if (!userLocation) {
    return;
  }

  // Check correct location and dispatch update event for DonationForm to be picked-up.
  if (EUCountryCodes.includes(userLocation)) {
    euroInput.checked = true;
    dispatchEvent(euroInput, ["change", "input"]);
  } else {
    dollarInput.checked = true;
    dispatchEvent(dollarInput, ["change", "input"]);
  }
};

import { publish } from "../observer";
import { sunglassesStoriesReceivedData } from "../observer-subjects";

const handleChange = (apiUrl) => async ({ target: { value } }) => {
  try {
    // Either fetch data with the search query or new initial data.
    const url = value
      ? `${apiUrl}?filter[search]=${value.replace(" ", "+")}`
      : apiUrl;

    const { data = [] } = await fetch(url).then((r) => r.json());

    // In case there is no data, use a custom card telling there is nothing found.
    const finalData =
      data.length > 0
        ? data
        : [
            {
              id: "000",
              type: "stories",
              attributes: {
                alias: "Nothing found",
                slug: "nothing-found",
                story: "Please try another alias",
              },
            },
          ];

    publish(sunglassesStoriesReceivedData, { stories: finalData });
  } catch (err) {
    publish(sunglassesStoriesReceivedData, { stories: [] });
  }
};

/**
 * Responsible for fetching data based on the search query.
 */
export const enhancer = (element) => {
  const input = element.querySelector("input");
  const apiUrl = element.getAttribute("data-api-url");

  if (!input || !apiUrl) return;

  input.addEventListener("input", handleChange(apiUrl));
};

import { publish } from "../observer";
import { updateCitizenScienceData } from "../observer-subjects";
import format from "./format-data";

/**
 * Get and format data.
 */
const fetchData = async (url, storedData = []) => {
  try {
    const response = await fetch(url);
    const { links, data } = await response.json();

    // Combine data.
    const combinedData = storedData.concat(data.map(format));

    // Publish new data.
    publish(updateCitizenScienceData, combinedData);

    if (links.next) {
      // Recursively calls itself.
      return await fetchData(links.next, combinedData);
    }

    return combinedData;
  } catch (err) {
    console.error(err);
    return storedData;
  }
};

export const getData = (apiUrl) => {
  const limit = 500;
  const url = `${apiUrl}?limit=${limit}`;

  return fetchData(url);
};

import { subscribe } from "./observer";
import { updateInPageNavigationActiveItem } from "./observer-subjects";

export const enhancer = (element) => {
  const handleUpdate = (heading) => {
    const currentHeadingText = element.innerText;
    const newHeadingText = heading.innerText;

    if (currentHeadingText === newHeadingText) return;

    element.innerHTML = heading.innerText;
  };
  subscribe(updateInPageNavigationActiveItem, handleUpdate);
};

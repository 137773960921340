/**
 * Generate a dateTime in a readable format.
 * The internationalization API is partly used, fully isn't possible with the
 * desired date-time format.
 */
export default (utcDate) => {
  // Remove +0000 from date, otherwise iOS will not accept the date format.
  const cutOfIndex = utcDate.includes("+")
    ? utcDate.indexOf("+")
    : utcDate.length;
  const formattedDate = utcDate.substr(0, cutOfIndex);

  const date = new Date(formattedDate);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const time = date
    .toLocaleString("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    })
    .toLocaleLowerCase()
    .replace(/\s/g, ""); // Remove space from string.

  return `${day} ${month} ${year} · ${time}`;
};

import { subscribe } from "../observer";
import {
  trashTrackerIsDoneDrawing,
  trashTrackerResetData,
  trashTrackerResetForm,
  trashTrackerUpdateData,
} from "../observer-subjects";

let reachesArctic = false;

/**
 * Called by observer.
 * Hide notification text.
 */
const hide = (element) => () => {
  element.setAttribute("data-show", false);
  element.setAttribute("aria-hidden", true);
};

/**
 * Called by observer.
 * Show/hide notification text.
 */
const handleDoneDrawing = (element) => () => {
  element.setAttribute("data-show", reachesArctic);
  element.setAttribute("aria-hidden", !reachesArctic);
};

/**
 * Called by observer.
 * Update module state based on isPolarTrajectory.
 */
const updateState = ({ isPolarTrajectory }) => {
  reachesArctic = isPolarTrajectory;
};

export const enhancer = (element) => {
  subscribe(trashTrackerResetData, hide(element));
  subscribe(trashTrackerResetForm, hide(element));
  subscribe(trashTrackerUpdateData, updateState);
  subscribe(trashTrackerIsDoneDrawing, handleDoneDrawing(element));
};

/* eslint-disable no-unused-vars */

import Flickity from "flickity-imagesloaded";

export const enhancer = (slider) => {
  const images = slider.querySelectorAll("img");
  if (!images.length || images.length < 2) {
    slider.parentNode.classList.add("is-single");
    return;
  }

  const flkty = new Flickity(slider, {
    autoPlay: false,
    cellAlign: "left",
    contain: true,
    freeScroll: false,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: true,
    arrowShape: "",
  });
};

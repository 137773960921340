import {
  supportsIntersectionObserver,
  prefersNoMotion,
  prefersToSaveData,
} from "./util";

/**
 * Called each time the intersection observers fires a new update.
 *
 * Hide and show the correct video based on the active intersection element.
 * Also starts and resets the video.
 */
const handleSectionInViewportUpdate = (targetStore, targetElements) => (
  entries,
  observer
) => {
  entries.forEach((entry) => {
    // Only act on sections that are intersecting.
    if (!entry.isIntersecting) {
      return;
    }

    const entryId = entry.target.getAttribute("data-observer-id");
    const newActiveSection = targetStore[entryId];

    // Bail out if the videos metadata is not yet loaded.
    if (newActiveSection.video.readyState === 4) {
      // Always start the video from the beginning.
      newActiveSection.video.currentTime = 0;
      newActiveSection.video.pause();
    }

    // Show and hide correct elements in a single loop
    // to prevent a flash of no video.
    targetElements.forEach((element) => {
      const elementId = element.getAttribute("data-observer-id");

      if (elementId === entryId) {
        element.setAttribute("aria-hidden", "false");
      } else {
        element.setAttribute("aria-hidden", "true");
      }
    });

    // Start the video after it's visible.
    newActiveSection.video.play();
  });
};

/**
 * Creates an object for each target element.
 * Key is based on the shared key of the trigger and target.
 * @param {Object} elements
 * @param {HTMLElement} element
 * @returns {Object}
 */
const createTargetStore = (elements, element) => {
  return {
    ...elements,
    [element.getAttribute("data-observer-id")]: {
      id: element.getAttribute("data-observer-id"),
      video: element.querySelector("video"),
      element,
    },
  };
};

/**
 * @param {Object} targetStore
 * @param {Array} targetElements
 */
const setupObserver = (targetStore, targetElements) => {
  const options = {
    threshold: 0.75,
  };

  // Make sure the observer callback can access the cached HTML elements
  return new IntersectionObserver(
    handleSectionInViewportUpdate(targetStore, targetElements),
    options
  );
};

/**
 * Observes given items on the page based on the scroll location.
 * Triggers animations and visibility changes on other (bound) elements.
 * @param {HTMLElement} element
 */
export const enhancer = (element) => {
  // Eject when intersection observer is not supported or for user preference
  if (!supportsIntersectionObserver() || prefersNoMotion || prefersToSaveData) {
    return;
  }

  // Get all sections to observe
  const triggerSelector = element.getAttribute("data-observer-triggers");
  const targetSelector = element.getAttribute("data-observer-targets");
  const triggerElements = Array.from(element.querySelectorAll(triggerSelector));
  const targetElements = Array.from(element.querySelectorAll(targetSelector));

  const targetStore = targetElements.reduce(createTargetStore, {});

  // Setup and activate observer
  const sectionObserver = setupObserver(targetStore, targetElements);
  triggerElements.forEach((triggerElement) => {
    sectionObserver.observe(triggerElement);
  });

  // Hide all videos by default to prevent visible stacked videos.
  targetElements.forEach((targetElement) =>
    targetElement.setAttribute("aria-hidden", "true")
  );
};

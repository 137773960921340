import { publish, subscribe, unsubscribe } from "./observer";
import { teamMember } from "./observer-subjects";

/**
 * Executed when an observer publishes a relevant event.
 * Deletes this element and unsubscribes itself for the observer.
 */
const deleteElement = (element) => (payload) => {
  element.setAttribute("aria-hidden", "true");
  unsubscribe(teamMember, deleteElement(element));
};

/**
 * Subscribe to observer to remove this element if a filter action is performed.
 */
export const enhancer = (element) => {
  subscribe(teamMember, deleteElement(element));
};

/**
 * Catch default behavior and notify observer to show all team members.
 */
export const handler = (element, event) => {
  event.preventDefault();

  publish(teamMember, {
    type: "showAll",
    id: "show-all-team-members",
  });
};

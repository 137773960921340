import { throttle } from "@grrr/utils";
import { isInViewport, previous, supportsIntersectionObserver } from "./util";

import { register as registerScrollListener } from "./scroll-listener";

const IO_THRESHOLD = [0, 0.1, 0.25, 0.5, 0.75, 0.9, 1];

const getAnchorHash = (anchor) =>
  anchor.hash || anchor.href.substr(anchor.href.indexOf("#"));

const shouldShowNav = ({ toc, items, current }) => {
  if (items[0] === current && !items[1].visibility) {
    return !isInViewport(toc);
  }
  return current && current.visibility > 0;
};

const updateNav = ({ toc, nav, items }) => {
  const current = items.reduce(
    (acc, item) => {
      return item.visibility && item.visibility > acc.visibility ? item : acc;
    },
    { visibility: 0 }
  );
  const showNav = shouldShowNav({ toc, items, current });
  nav.setAttribute("aria-hidden", !showNav);
  document.body.setAttribute("data-sticky-toc", showNav);
  items.map((item) =>
    item.anchor.setAttribute(
      "aria-current",
      current && current.anchor === item.anchor
    )
  );
};

const toggleNav = ({ nav, button }) => {
  const shouldHide = button.getAttribute("aria-expanded") === "true";
  nav.setAttribute("aria-hidden", shouldHide);
  button.setAttribute("aria-expanded", !shouldHide);
};

const attachReadIndicator = (nav) => {
  const readIndicator = nav.querySelector("progress");
  const scrollHandler = (e) => {
    const pageLength = document.body.scrollHeight - window.innerHeight;
    const percentage = (window.pageYOffset / pageLength) * 100; // length to percentage
    readIndicator.value = percentage;
  };
  registerScrollListener("readIndicatorListener", throttle(scrollHandler, 20));
};

const createNav = (tocItems) => {
  const wrapper = document.createElement("header");
  wrapper.setAttribute("role", "banner");
  wrapper.setAttribute("class", "sticky-toc js-fixed-header");
  wrapper.setAttribute("aria-hidden", "true");
  wrapper.innerHTML = `
    <a href="/" aria-label="Go to: homepage"><span>The Ocean Cleanup</span></a>
    <nav aria-hidden="true" id="sticky-toc"><ol>${tocItems}</ol></nav>
    <button data-handler="stickyTocToggle" aria-label="Toggle navigation" aria-controls="sticky-toc" aria-expanded="false"></button>
    <progress aria-hidden="true" class="sticky-toc__read-indicator" max="100"></progress>
  `;
  return wrapper;
};

const trackWithObserver = ({ toc, nav, items }) => {
  const observerCallback = (entries, observer) => {
    entries.map((entry) => {
      const index = items.findIndex((item) => item.section === entry.target);
      items[index] = { ...items[index], visibility: entry.intersectionRatio };
    });
    updateNav({ toc, nav, items });
  };
  const observer = new IntersectionObserver(observerCallback, {
    threshold: IO_THRESHOLD,
  });
  items.map((item) => observer.observe(item.section));
  observer.observe(toc);
};

export const enhancer = (el) => {
  if (!supportsIntersectionObserver()) {
    return;
  }
  // Clone table of contents and create navigation.
  const nav = createNav(el.innerHTML);
  document.body.appendChild(nav);

  // Attach observer.
  const items = [...nav.querySelectorAll("li a")].map((anchor) => {
    const section = document.querySelector(getAnchorHash(anchor));
    return { anchor, section };
  });
  trackWithObserver({ toc: el, nav, items });

  // Attach read indicator.
  attachReadIndicator(nav);

  // Toggle nav on click (item when open, or the nav itself when closed).
  nav.querySelector("ol").addEventListener("click", (e) => {
    toggleNav({
      nav: nav.querySelector("nav"),
      button: nav.querySelector("button"),
    });
    window.setTimeout(e.target.blur(), 0);
  });
};

export const handler = (button, e) => {
  e.preventDefault();
  toggleNav({ nav: previous(button), button });
  button.blur();
};

import { subscribe } from "./observer";
import { teamMember } from "./observer-subjects";

/**
 * Called by observer.
 *
 * Shows team member if they match the role or if all members should be shown.
 * Otherwise hide the team member.
 */
const handleFilterAction = (element) => (roles) => ({ id }) => {
  if (id === "all" || id === "show-all-team-members" || roles.includes(id)) {
    element.setAttribute("aria-hidden", "false");
  } else {
    element.setAttribute("aria-hidden", "true");
  }
};

/**
 * Filter team members.
 * Subscribe to observer to react on each filter action.
 */
export const enhancer = (element) => {
  const roles = element.getAttribute("data-roles").split(",");

  subscribe(teamMember, handleFilterAction(element)(roles));
};

import { subscribe } from "./observer";

/**
 * Highlights an element inside the illustration.
 */
const highlight = (targetElement) => {
  targetElement.setAttribute("data-highlight", "true");
};
/**
 * Dims an element inside the illustration.
 */
const dim = (targetElement) => {
  targetElement.setAttribute("data-highlight", "false");
};

const handleActiveCard = (element, id) => (payload) => {
  if (payload.id === id || payload.id === "total") {
    highlight(element);
  } else {
    dim(element);
  }
};

export const enhancer = (element) => {
  const id = element.getAttribute("data-plastic-journey-highlight-id");
  subscribe("plastic-journey-active-card", handleActiveCard(element, id));
};

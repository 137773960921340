import { publish } from "../observer";
import { sunglassesStoriesReceivedData } from "../observer-subjects";

/**
 * Responsible for the initial data fetching.
 */
export const enhancer = async (element) => {
  const apiUrl = element.getAttribute("data-api-url");
  const totalElement = element.querySelector('[data-element="total"]');

  // Fetch initial data
  try {
    const {
      data = [],
      meta: {
        page: { total = 0 },
      },
    } = await fetch(apiUrl).then((r) => r.json());

    const params = new URL(window.location.href).searchParams;
    const alias = params.get("alias");

    // In case there is an alias in the query, fetch all stories with this alias
    // (it will not always be here by default.)
    if (alias) {
      const url = `${apiUrl}?filter[search]=${alias.replace(" ", "+")}`;
      const aliasData = await fetch(url).then((r) => r.json());
      publish(sunglassesStoriesReceivedData, {
        stories: [...aliasData.data, ...data],
      });
    } else {
      publish(sunglassesStoriesReceivedData, {
        stories: data,
      });
    }

    // structure of a single story:
    // {
    //   id: "56",
    //   type: "stories",
    //   attributes: {
    //     alias: "Angel Beatty",
    //     slug: "angel-beatty",
    //     story: "Et possimus dolorum vel id.",
    //   },
    //   links: {
    //     self: "http://localhost:8001/api/v1/stories/56",
    //   },
    // };

    totalElement.innerHTML = total;
  } catch (err) {
    publish(sunglassesStoriesReceivedData, { stories: [] });
  }
};

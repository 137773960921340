import { publish, subscribe } from "./observer";
import { openPopup, closePopup } from "./observer-subjects";

/**
 * Called by event listener(s).
 * Update the visibility of the popup.
 */
const handleMouseLeave = (htmlElement, id) =>
  function mouseLeaveHandler(event) {
    publish(openPopup, id);
    document.removeEventListener("mouseleave", mouseLeaveHandler, false);
    htmlElement.removeEventListener("mouseleave", mouseLeaveHandler, false);
  };

/**
 * Called by event listener(s).
 * Add to localStorage with expiry date
 */
const setWithExpiry = (value) => {
  // Todays date
  const today = new Date();
  // Next week's date
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  // Prepare an object with a keyvalue and expirydate
  const item = {
    value,
    expiredOn: nextWeek,
  };
  // Set localStorage
  localStorage.setItem("show-email-popup", JSON.stringify(item));
};

/**
 * Called by enhancer.
 * Add to localStorage with expiry date
 */
const getWithExpiry = () => {
  const itemString = localStorage.getItem("show-email-popup");
  // if the item doesn't exist, return false
  if (!itemString) {
    return false;
  }
  const item = JSON.parse(itemString);
  const today = new Date();

  const expiredOn = new Date(item.expiredOn).getTime();
  // compare the expiry time of the item with the current time
  if (today > expiredOn) {
    // If the item is expired, delete the item from storage
    // and return false
    localStorage.removeItem("show-email-popup");
    return false;
  }
  return true;
};

const onPopupClose = () => {
  setWithExpiry(false);
};

/**
 * Show popup when the user leaves the screen.
 */
export const enhancer = (element) => {
  const htmlElement = document.querySelector("html");
  const id = element.getAttribute("data-popup-id");

  subscribe(closePopup, () => onPopupClose());

  const shouldPopupOpen = getWithExpiry();
  // When shouldPopupOpen is false don't attach handlers
  if (!shouldPopupOpen) {
    const mouseLeaveHandler = handleMouseLeave(htmlElement, id);

    // Chrome
    document.addEventListener("mouseleave", mouseLeaveHandler, false);
    // Firefox/Safari
    htmlElement.addEventListener("mouseleave", mouseLeaveHandler, false);
  }
};

import { matchesBreakpoint } from "./responsive";
import {
  throttle,
  prefersNoMotion,
  prefersToSaveData,
  supportsIntersectionObserver,
} from "./util";

/**
 * Called when video starts intersecting
 */
const playVideo = (videoElement) => {
  videoElement.play();
};

/**
 * Called when video stops intersecting
 */
const pauseAndResetVideo = (videoElement) => {
  // Bail out if the videos metadata is not yet loaded.
  if (videoElement.readyState !== 4) {
    return;
  }

  videoElement.currentTime = 0;
  videoElement.pause();
};

const handleIntersect = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      playVideo(entry.target);
    } else {
      pauseAndResetVideo(entry.target);
    }
  });
};

const createObserver = () => {
  const observerOptions = {
    threshold: [1, 0.9],
  };

  return new IntersectionObserver(handleIntersect, observerOptions);
};

/**
 * Handles a window resize event.
 * Checks if the video is visible (will not be visible after given window width).
 * Takes the current observation state into consideration,
 * to prevent multiple observers on the same element.
 */
const handleWindowResize = (videoObserver, element, breakpoint) => {
  const screenIsTooLarge = !matchesBreakpoint(breakpoint);

  if (screenIsTooLarge) {
    videoObserver.observe(element);
  } else {
    videoObserver.unobserve(element);
  }
};

/**
 * Autoplay/Auto-pause the video when the video appears in the viewport
 * @param {HTMLElement} element
 */
export const enhancer = (element) => {
  if (!supportsIntersectionObserver || prefersNoMotion || prefersToSaveData) {
    return;
  }
  const breakpoint = element.getAttribute("data-end-observing-breakpoint");

  const videoObserver = createObserver();

  if (!breakpoint) {
    videoObserver.observe(element);
    return;
  }

  window.addEventListener(
    "resize",
    throttle(() => handleWindowResize(videoObserver, element, breakpoint), 500)
  );
  window.addEventListener(
    "orientationchange",
    throttle(() => handleWindowResize(videoObserver, element, breakpoint), 500)
  );

  handleWindowResize(videoObserver, element, breakpoint);
};

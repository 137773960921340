/**
 * Expands lists (or actually any sibling element that is aria-hidden)
 */

export const handler = (el, e) => {
  e.preventDefault();

  const list = el.parentNode.querySelector('[aria-hidden="true"]');
  if (!list) {
    return;
  }

  list.setAttribute("aria-hidden", "false");
  el.parentNode.removeChild(el);
};

const COUNTRY_CODES_ATTRIBUTE = "data-country-codes";

export const enhancer = (container) => {
  const map = container.querySelector("svg");

  const countryCodes = container
    .getAttribute(COUNTRY_CODES_ATTRIBUTE)
    .split(",");
  const groupSelector = countryCodes.map((code) => `[cc="${code}"]`);
  const parts = map.querySelectorAll(groupSelector.join(", "));

  parts.forEach((part) => part.setAttribute("data-highlighted", "true"));
};

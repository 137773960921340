/* eslint-disable no-restricted-globals */

import { debounce } from "./util";

/**
 * Post height of iframe to parent window.
 */
export const enhancer = (element) => {
  const handleResize = () => {
    const { height } = element.getBoundingClientRect();

    if (parent) {
      // Post height of the iframe so the parent window can update the height of the iframe.
      parent.postMessage({ frameHeight: Math.round(height) }, "*");
    }
  };
  // Execute the resize function on load as well.
  handleResize();

  window.addEventListener("resize", debounce(handleResize, 200));
  window.addEventListener("orientationchange", debounce(handleResize, 200));
};

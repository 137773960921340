import { dispatchEvent } from "./util";
import getUserLocation from "../utils/get-user-location";

/**
 * Will pre-select the country.
 *
 * - If a country is found in localStorage it will be used.
 * - Will fetch country from the donation microservice and store it in localStorage.
 * - Changing the country by the user will store that as a preference.
 */
const CountrySelect = (select) => {
  const DATA_STORAGE_KEY = "donate-country";
  const API_ENDPOINT = select.getAttribute("data-api-endpoint");

  const storeCountryInStorage = (code) => {
    localStorage.setItem(DATA_STORAGE_KEY, code);
  };

  const getCountryFromStorage = () => {
    return localStorage.getItem(DATA_STORAGE_KEY) || null;
  };

  const selectCountry = (code) => {
    const option = select.querySelector(`option[value="${code}"]`);
    if (!option) {
      return;
    }
    option.selected = true;
    storeCountryInStorage(code);
    dispatchEvent(select, ["change", "input"]);
  };

  return {
    async init() {
      select.addEventListener("change", (e) => {
        storeCountryInStorage(select.value);
      });

      if (getCountryFromStorage()) {
        selectCountry(getCountryFromStorage());
      } else {
        selectCountry(await getUserLocation(API_ENDPOINT));
      }
    },
  };
};

export const enhancer = (select) => {
  const countrySelect = CountrySelect(select);
  countrySelect.init();
};

import { throttle } from "@grrr/utils";
import { isScrolledPast, register } from "./scroll-listener";

/**
 * Unhide element when it's scroll passed.
 *
 * Used for sticky elements that should be visible when user scrolls past them.
 */
export const enhancer = (element) => {
  const scrollHandler = (e) => {
    element.setAttribute(
      "data-hidden",
      isScrolledPast(element) ? "false" : "true"
    );
  };

  register(`media-gallary`, throttle(scrollHandler, 100));
};

import { subscribe } from "../observer";
import { trashTrackerIsLoading } from "../observer-subjects";

const handleLoadingStateChange = (element) => (state) => {
  element.setAttribute("data-show", state);
};

export const enhancer = (element) => {
  subscribe(trashTrackerIsLoading, handleLoadingStateChange(element));
};

import { publish, subscribe } from "./observer";
import { teamMember } from "./observer-subjects";

/**
 * Called by observer.
 * Sets/removes the aria-current label based on the payload.
 */
const handleFilterAction = (element) => ({ type, id }) => {
  // Don't update if it's not a filter action
  if (type !== "filter") {
    return;
  }

  if (element.getAttribute("data-slug") === id) {
    element.setAttribute("aria-current", "true");
  } else {
    element.setAttribute("aria-current", "false");
  }
};

/**
 * Subscribe to the observer to set the current aria property.
 */
export const enhancer = (element) => {
  subscribe(teamMember, handleFilterAction(element));
};

/**
 * Prevent default navigation.
 * Notify observer of filter action and communicate the slug.
 */
export const handler = (element, event) => {
  event.preventDefault();

  const id = element.getAttribute("data-slug");
  const subject = element.getAttribute("data-subject");

  publish(subject, {
    type: "filter",
    id,
  });
};

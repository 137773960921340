import { scrollTo } from "./scroll-to";

export const enhancer = (el) => {
  const expand = el.querySelector(".js-expand");
  const collapse = el.querySelector(".js-collapse");

  const togglePublication = (e) => {
    e.preventDefault();
    const body = el.querySelector(".js-body");
    const isHidden = body.getAttribute("aria-hidden") === "true";
    expand.setAttribute("aria-hidden", isHidden);
    body.setAttribute("aria-hidden", !isHidden);

    // Scroll to top of the block when collapsing the block.
    if (!isHidden) {
      scrollTo(el);
    }
  };

  if (!expand || !collapse) {
    return;
  }

  expand.addEventListener("click", togglePublication);
  collapse.addEventListener("click", togglePublication);
};

import { generateUuid } from "./util";

const ARCHIVE_SELECTOR = ".js-archive";

const loadImages = (archive) => {
  [...archive.querySelectorAll("img")].map((image) => {
    const src = image.getAttribute("data-src");
    const srcset = image.getAttribute("data-srcset");
    if (!src) {
      return;
    }
    image.setAttribute("src", src);
    image.setAttribute("srcset", srcset);
  });
};

const toggleArchive = (button, archive) => {
  const shouldHide = archive.getAttribute("aria-hidden") === "false";

  archive.setAttribute("aria-hidden", shouldHide);
  button.setAttribute("aria-expanded", !shouldHide);

  button.querySelector("span").textContent = shouldHide
    ? button.getAttribute("data-original-label")
    : "See less";

  button.blur();
  loadImages(archive);
};

const init = (button, archive) => {
  const uuid = generateUuid();

  button.setAttribute(
    "data-original-label",
    button.querySelector("span").textContent
  );
  button.setAttribute("aria-expanded", "false");
  button.setAttribute("aria-controls", `archive-${uuid}`);
  archive.setAttribute("id", `archive-${uuid}`);

  button.addEventListener("click", (e) => {
    e.preventDefault();
    toggleArchive(button, archive);
  });
};

export const enhancer = (button) => {
  const archive = button.parentNode.querySelector(ARCHIVE_SELECTOR);
  if (!archive) {
    return;
  }
  init(button, archive);
};

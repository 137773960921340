/* eslint-disable no-undef */

/**
 * Add pointers to the container of the chart.
 */
const addPointers = (arc, pieData, addHoverSubscribes) => {
  // Append pointers to chart.
  const container = document.querySelector(".js-pie-chart-pointers");

  // Remove possible elements in container.
  container.innerHTML = null;

  // Append pointers to container.
  pieData.forEach((piePart) => {
    // Create element.
    const element = document.createElement("p");
    element.classList.add("pointer");
    element.setAttribute("data-type", piePart.data.type);

    // Calculate the transform values used for positioning the pointer.
    // Calculation is based on the center of the ard and the position of the corresponding pie-part.
    // All calculations are done by the D3 centroid method.
    const translateValues = arc
      .centroid(piePart)
      .map((item) => Math.round(item))
      .map((item) => `${item}px`);
    element.style.transform = `translate(${translateValues})`;

    // Set HTML.
    element.innerHTML = `<span class="pointer__count">${piePart.data.type}</span> ${piePart.data.value} pieces counted`;

    // Subscribe to hover events thrown by observer.
    addHoverSubscribes(element);

    // Append new element to container.
    container.appendChild(element);
  });
};

/**
 * Add labels around the chart, places in the outer arc.
 */
const addLabels = (svg, arc, pieData) => {
  svg
    .selectAll("allLabels")
    .data(pieData)
    .enter()
    .append("text")
    .attr("transform", (d) => `translate(${arc.centroid(d)})`)
    .style("text-anchor", (d) => {
      // Determine how to align the label based on the arc.
      const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
      return midangle < Math.PI ? "start" : "end";
    })
    .style("fill", "white")
    .append("tspan")
    .text((d) => (d.data.displayValue === 0 ? "" : d.data.displayValue))
    .attr("y", "5");
};

/**
 * Create the donut chart
 */
const addPieParts = (svg, arc, pieData) => {
  svg
    .selectAll("parts")
    .data(pieData)
    .enter()
    .append("path")
    .attr("d", arc)
    .attr("stroke", "#023755")
    .style("stroke-width", "2px")
    .attr("data-type", (d) => d.data.type)
    .attr("data-element", "pie-part")
    .attr("aria-labelledby", (d) => d.data.type);
};

/**
 * Create the donut chart.
 * This function contains all configuration and execution of generation functions.
 * Code based on example: https://www.d3-graph-gallery.com/graph/donut_label.html.
 */
export default (containerElement, data, addHoverSubscribes) => {
  // Set size at 90% of the container width.
  const sizePercentage = 90;
  const width = (containerElement.clientWidth / 100) * sizePercentage;
  const height = (containerElement.clientWidth / 100) * sizePercentage;

  // Calculate the radius.
  const margin = 15;
  const radius = Math.min(width, height) / 2 - margin;

  // Format data if there is no data available.
  const pieData = data.length
    ? data
    : [{ index: 0, type: "none", value: 1, displayValue: 0 }];

  // Create empty SVG element for chart.
  const svg = d3
    .create("svg")
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .attr("width", width)
    .attr("height", height);

  // Create data for pie chart formatted for the D3 engine.
  // Value function is needed for data mapping (https://github.com/d3/d3-shape/blob/v2.0.0/README.md#pie_value).
  // Note: Function execution must happen after the chain. Otherwise a default JS sort is performed.
  const pie = d3
    .pie()
    .value((d) => d.value)
    .sort(null)(pieData);

  // create arc for the pie chart.
  const arc = d3
    .arc()
    .innerRadius(radius * 0.5) // Size of the donut hole.
    .outerRadius(radius * 0.75);

  // create arc for chart labels.
  const outerArc = d3
    .arc()
    .innerRadius(radius * 0.85)
    .outerRadius(radius * 0.85);

  // Create donut chart.
  addPieParts(svg, arc, pie);

  // Create labels.
  addLabels(svg, outerArc, pie);

  // Create pointers.
  addPointers(arc, pie, addHoverSubscribes);

  // Return SVG HTML.
  return svg.node();
};

import URLSearchParams from "@ungap/url-search-params";
import { prop, uuid } from "@grrr/utils";
import { purchase as gtmPurchase } from "./donate-gtm";

const PARAMS_EUR = {
  id: "transaction_id",
  amount: "amount",
  currency: "currency",
  frequency: "frequency",
  paymentmethod: "provider",
};

// See:
// - https://developer.paypal.com/docs/payment-data-transfer/
// - https://developer.paypal.com/docs/classic/products/payment-data-transfer/
//
// One-time callback data via GET:
// <url>/?tx=72L00569LX216132F&st=Completed&amt=1.00&cc=USD&cm=&item_number=
//
// Monthly subscription callback data via GET:
// https://theoceancleanup.com/donate/thank-you/?tx=44J44534N6649015D&st=Completed&amt=1.00&cc=USD&cm=&item_number=&sig=HzL4FVBHzXr4QU0%2FmXau4Oe%2BtEU%2B0ccMnUCBSk90%2BGnXlKxjp%2FcGTg%2BfHFPlphIkudeKjnAt4xMLr2Huld1raw9tAM4WUllRfLKrKLemYvXUr9L3Nsv0vc7qtcJhOifLRMcilUeW0LNe9gg7XaHzVVhI2jyixVOQ9uPVTlqvewc%3D
const PARAMS_USD = {
  id: "tx",
  amount: "amt",
  currency: "cc",
  frequency: "fr",
  status: "st",
};

export const enhancer = (el) => {
  const searchParams = new URLSearchParams(window.location.search);

  const currency = searchParams.get("currency");
  const params = currency === "EUR" ? PARAMS_EUR : PARAMS_USD;

  // Do not track non-completed PayPal Donate payments.
  if (currency === "USD" && prop("status", params) !== "Completed") {
    return;
  }

  gtmPurchase({
    id: searchParams.get(prop("id", params)) || `fake-${uuid()}`,
    amount: searchParams.get(prop("amount", params)),
    currency: searchParams.get(prop("currency", params)),
    frequency: searchParams.get(prop("frequency", params)) || "unknown",
    paymentMethod:
      currency === "EUR"
        ? searchParams.get(prop("paymentmethod", params))
        : "paypal",
  });
};

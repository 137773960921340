import { trapFocus } from "@grrr/utils";
import { publish, subscribe } from "./observer";
import { closePopup, openPopup, forceClosePopup } from "./observer-subjects";

// Needed to remove the event listener.
let memoizedClickHandler = null;

/**
 * Force close popup when the escape key is pressed and remove all relevant
 * event listeners.
 */
const forceClose = (event) => {
  if (event.keyCode === 27) {
    publish(forceClosePopup);
    window.removeEventListener("keydown", forceClose);

    document.removeEventListener("click", memoizedClickHandler, {
      capture: true,
    });
  }
};

// Global var needed to store the focus trap instance.
let focusTrap = null;

const trapTheFocus = (element) => {
  focusTrap = trapFocus(element.firstElementChild);
};
const releaseFocus = () => {
  if (focusTrap) {
    focusTrap.release();
  }
};

/**
 * Update state of popup.
 * Each time the popup is imported in the template a separate instance of this
 * enhancer is called. Therefore on a popup observer message, each individual
 * popup will handle it's own state.
 */
const updatePopupState = (element, bodyElement, { open }) => (id) => {
  const elementPopupId = element.getAttribute("data-popup-id");

  // Update visibility of individual popup element.
  if (elementPopupId === id) {
    element.setAttribute("data-visible", open);
    element.setAttribute("aria-hidden", !open);
  } else {
    element.setAttribute("data-visible", false);
    element.setAttribute("aria-hidden", true);
  }

  // Don't apply the focus trap if it's explicitly prevented.
  const preventFocusTrap = element.getAttribute("data-prevent-focus-trap");
  if (preventFocusTrap !== "true" && open && elementPopupId === id) {
    trapTheFocus(element);
  }
  if (preventFocusTrap !== "true" && !open && elementPopupId === id) {
    releaseFocus();
  }

  // Bail out if it's allowed to scroll when the popup is open.
  const allowScrollWhenOpen = element.getAttribute(
    "data-allow-scroll-when-opened"
  );
  if (elementPopupId !== id || allowScrollWhenOpen === "true") return;

  // Disable scrolling of the page if the popup is open.
  bodyElement.setAttribute("popup-is-open", open);
};

/**
 * Close popup even if the caller doesn't know the popup id.
 */
const handleForceClosePopup = (element, bodyElement) => () => {
  element.setAttribute("data-visible", "false");
  element.setAttribute("aria-hidden", "true");

  // Disable scrolling of the page if the popup is open.
  bodyElement.setAttribute("popup-is-open", "false");

  releaseFocus();
};

/**
 * Add subscriptions.
 */
export const enhancer = (element) => {
  const bodyElement = document.querySelector("body");

  subscribe(openPopup, updatePopupState(element, bodyElement, { open: true }));
  subscribe(
    closePopup,
    updatePopupState(element, bodyElement, { open: false })
  );

  subscribe(forceClosePopup, handleForceClosePopup(element, bodyElement));
};

/**
 * Close popup if the user clicks outside of the box (trigger of target).
 */
const handleClickOutside = (element) => (event) => {
  const bodyElement = document.querySelector("body");

  const id = element.getAttribute("data-popup-id");
  const targetElement = document.querySelector(
    `[data-enhancer="popup"][data-popup-id="${id}"]`
  );

  const isClickInside = targetElement.contains(event.target);
  if (isClickInside) return;

  publish(closePopup, updatePopupState(element, bodyElement, { open: false }));
  document.removeEventListener("click", memoizedClickHandler, {
    capture: true,
  });
};

/**
 * Handler to open the popup.
 */
export const openPopupHandler = (element, event) => {
  publish(openPopup, element.getAttribute("data-popup-id"));
  window.addEventListener("keydown", forceClose);

  const closePopupOnOutsideClick = element.getAttribute(
    "data-close-popup-on-outside-click"
  );
  if (closePopupOnOutsideClick !== "true") return;

  memoizedClickHandler = handleClickOutside(element);
  // Prevent an immediate close of the popup with the same click as the popup opened.
  setTimeout(() => {
    document.addEventListener("click", memoizedClickHandler, { capture: true });
  }, 100);
};

/**
 * Handler to close the popup.
 */
export const closePopupHandler = (element, event) => {
  publish(closePopup, element.getAttribute("data-popup-id"));
  window.removeEventListener("keydown", forceClose);
  document.removeEventListener("click", memoizedClickHandler, {
    capture: true,
  });
};

import { publish, subscribe } from "./observer";
import { showInfoBox, showMetaBox, hideOverlays } from "./observer-subjects";

const showMeta = (trigger, target) => () => {
  target.setAttribute("aria-hidden", "false");
  trigger.setAttribute("aria-expanded", "true");
};
const hideMeta = (trigger, target) => () => {
  target.setAttribute("aria-hidden", "true");
  trigger.setAttribute("aria-expanded", "false");
};

/**
 * Updates aria attributes of the trigger and target.
 */
const handleClick = (trigger, target) => () => {
  if (target.getAttribute("aria-hidden") === "true") {
    showMeta(trigger, target)();
    return;
  }
  hideMeta(trigger, target)();
};

/**
 * Toggle the map-meta component.
 */
export const enhancer = (element) => {
  const triggerSelector = element.getAttribute("data-trigger-selector");
  const trigger = document.querySelector(triggerSelector);

  trigger.addEventListener("click", handleClick(trigger, element));

  subscribe(showInfoBox, hideMeta(trigger, element));
  subscribe(hideOverlays, hideMeta(trigger, element));
  subscribe(showMetaBox, showMeta(trigger, element));
};

/**
 * Handle click on the meta-preview.
 */
export const handler = () => {
  publish(showMetaBox);
};

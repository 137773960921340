/* eslint-disable no-undef */
import { publish, subscribe, unsubscribe } from "../observer";
import {
  trashTrackerUpdateData,
  trashTrackerResetData,
  hideOverlays,
  trashTrackerIsLoading,
  trashTrackerError,
  trashTrackerHasSubmitted,
  trashTrackerIsDoneDrawing,
  trashTrackerResetForm,
  setHideInfoBox,
  trashTrackerSetLatLong,
} from "../observer-subjects";
import { getData } from "./data";
import { pushEvent } from "../gtm-event";

// search status, matches the GTM values.
let searchStatus = "search_initial"; // search_initial || search_redraw || search_draw_new

// API URL
let API_URL = "";

/**
 * Called by observer.
 * Show form when info box is closed for the first time
 * and unsubscribe from observer
 */
const handleHideInfoBox = (element) => () => {
  element.setAttribute("data-visible", "true");
  unsubscribe(setHideInfoBox, handleHideInfoBox(element));
};

/**
 * GTM push event.
 */
const pushTrashTrackerEvent = (action, label) => {
  pushEvent({
    event: "event",
    eventAction: action,
    eventCategory: "plastic-tracker",
    eventLabel: label,
  });
};

/**
 * Handle the form submit.
 * Is only called when the user types a location and DOESN'T use the Google Dropdown.
 */
const handleSubmit = (inputElement) => (event) => {
  event.preventDefault();

  if (!inputElement.value) {
    publish(trashTrackerError, {
      showError: true,
      title: "No location",
      text: "Please add a location.",
    });

    return;
  }
  if (!google) {
    publish(trashTrackerError, {
      showError: true,
      title: "Service not available",
      text: "Please try again",
    });

    return;
  }

  // Create Geocoder instance.
  const geocoder = new google.maps.Geocoder();

  // Get lat/long based on user typed input.
  geocoder.geocode({ address: inputElement.value }, (results, status) => {
    if (status === "OK" && results.length) {
      publish(trashTrackerSetLatLong, {
        lat: results[0].geometry.location.lat(),
        long: results[0].geometry.location.lng(),
        placename: inputElement.value,
      });
    } else {
      publish(trashTrackerError, {
        showError: true,
        title: "Invalid location",
        text: "Provide a valid location",
      });
    }
  });
};
/**
 * Called by observer.
 * Get data, set loading screen and handle the possible error.
 */
const handleSetLatLong = (inputElement, errorMessages) => async ({
  lat,
  long,
  placename,
}) => {
  pushTrashTrackerEvent(searchStatus, placename);

  // Update GTM status to redraw.
  searchStatus = "search_redraw";

  inputElement.setAttribute("data-lat", lat);
  inputElement.setAttribute("data-long", long);
  inputElement.setAttribute("data-place-name", placename);

  // Reset possible errors and current data.
  publish(trashTrackerError, { showError: false });
  publish(trashTrackerResetData);
  publish(trashTrackerHasSubmitted, {
    location: placename,
  });

  // Get and publish data for map.
  publish(hideOverlays);
  publish(trashTrackerIsLoading, true);

  if (lat && long) {
    const response = await getData(API_URL, lat, long);
    publish(trashTrackerIsLoading, false);

    // Handle Error
    if (!response.data || response.status !== 200) {
      if (response.status === 503) {
        publish(trashTrackerError, {
          showError: true,
          title: errorMessages.error_title_503 || "",
          text: errorMessages.error_text_503 || "",
        });
        return;
      }
      if (response.status === 409) {
        publish(trashTrackerError, {
          showError: true,
          title: errorMessages.error_title_409 || "",
          text: errorMessages.error_text_409 || "",
        });
        return;
      }
      if (response.status === 500) {
        publish(trashTrackerError, {
          showError: true,
          title: errorMessages.error_title_500 || "",
          text: errorMessages.error_text_500 || "",
        });
        return;
      }

      // If non of the above status codes match, but there is still an error.
      // The default error text (from the CMS is used here).
      publish(trashTrackerError, { showError: true });
      return;
    }

    publish(trashTrackerUpdateData, response.data);
  }
};

/**
 * Called by observer.
 * Disable submit button if the loading state is enabled.
 */
const handleLoadingStateChange = (submitElement, content) => (state) => {
  submitElement.containerElement.disabled = state;
  submitElement.containerElement.setAttribute("data-is-loading", state);
  submitElement.labelElement.innerHTML = content;
};

/**
 * Called by observer.
 * Update form location and hide original input.
 */
const handleMove = (el, inputWrapper, submitElement, inputReset) => () => {
  el.setAttribute("data-location", "aside");
  inputReset.setAttribute("data-visible", "false");
  inputWrapper.setAttribute("data-visible", "false");
  submitElement.containerElement.setAttribute("data-visible", "true");
  submitElement.containerElement.setAttribute("data-is-loading", "true");
};

/**
 * Called by observer.
 * Resets form and shows original input.
 */
const handleFormReset = (
  formElement,
  inputWrapper,
  submitElement,
  inputReset,
  submitLabel
) => () => {
  inputWrapper.setAttribute("data-visible", "true");
  inputReset.setAttribute("data-visible", "false");
  submitElement.containerElement.setAttribute("data-visible", "true");
  submitElement.containerElement.setAttribute("data-is-loading", "false");
  submitElement.labelElement.innerHTML = submitLabel;
  formElement.reset();
};

/**
 * Called by observer.
 * Updates button state.
 */
const updateButton = (submitElement, content, isDisabled) => () => {
  submitElement.containerElement.disabled = isDisabled;
  submitElement.containerElement.setAttribute("data-is-loading", isDisabled);
  submitElement.labelElement.innerHTML = content;
};

/**
 * Called by observer.
 * Updates button state after the line is done with drawing.
 */
const handleDoneDrawing = (submitElement, inputReset) => () => {
  // Set done drawing state.
  submitElement.containerElement.disabled = false;
  submitElement.containerElement.setAttribute("data-is-loading", "false");
  submitElement.containerElement.setAttribute("data-visible", "false");
  inputReset.setAttribute("data-visible", "true");
};

/**
 * Called by observer.
 * Enables input reset.
 */
const handleError = (inputReset, submitElement) => () => {
  inputReset.setAttribute("data-visible", "true");
  submitElement.containerElement.setAttribute("data-visible", "false");
};

export const enhancer = (element) => {
  API_URL = element.getAttribute("data-api-url");

  const errorMessages = JSON.parse(element.getAttribute("data-error-messages"));

  const submitElement = {
    containerElement: element.querySelector(".js-submit"),
    labelElement: element.querySelector(".js-submit-label"),
  };

  const content = {
    buttonIsFetching: submitElement.containerElement.getAttribute(
      "data-retrieving-data-label"
    ),
    buttonSubmit: submitElement.containerElement.getAttribute(
      "data-submit-label"
    ),
    buttonIsDrawing: submitElement.containerElement.getAttribute(
      "data-drawing-label"
    ),
  };

  const inputWrapper = element.querySelector(".js-input-wrapper");
  const inputElement = element.querySelector(".js-input");
  const inputReset = element.querySelector(".js-input-reset");

  element.addEventListener("submit", handleSubmit(inputElement));
  subscribe(
    trashTrackerSetLatLong,
    handleSetLatLong(inputElement, errorMessages)
  );

  subscribe(setHideInfoBox, handleHideInfoBox(element));
  subscribe(
    trashTrackerIsLoading,
    handleLoadingStateChange(submitElement, content.buttonIsFetching)
  );

  subscribe(
    trashTrackerHasSubmitted,
    handleMove(element, inputWrapper, submitElement, inputReset)
  );

  subscribe(
    trashTrackerUpdateData,
    updateButton(submitElement, content.buttonIsDrawing, true)
  );

  subscribe(
    trashTrackerIsDoneDrawing,
    handleDoneDrawing(submitElement, inputReset)
  );
  subscribe(
    trashTrackerResetForm,
    handleFormReset(
      element,
      inputWrapper,
      submitElement,
      inputReset,
      content.buttonSubmit
    )
  );
  subscribe(trashTrackerError, handleError(inputReset, submitElement));
};

/**
 * Reset handler.
 */
export const handler = (element, event) => {
  event.preventDefault();

  // Update GTM status to search new.
  searchStatus = "search_draw_new";

  publish(trashTrackerResetForm);
  publish(trashTrackerError, { showError: false });
  publish(trashTrackerResetData);
};

/**
 * @TODO this needs to be properly ESLint-fixed...
 */

/* eslint-disable */

/**
 * Milestones list/slider
 *
 * On desktop backgrounds are lazy-loaded.
 * On mobile images are lazy-appended to the listing item. Descriptions are
 *
 */

import objectFitImages from "object-fit-images";
import SweetScroll from "sweet-scroll";
import Stickyfill from "stickyfilljs";
import { matchesBreakpoint, getCurrentBreakpoint } from "./responsive";

const BACKGROUND_FIGURE = ".js-milestones-background-figure";
const LISTING = ".js-milestones-listing";
const DESCRIPTIONS = ".js-milestones-description";
const STICKY = ".js-milestones-sticky";

function removeImage(img) {
  img.parentNode.removeChild(img);
}

function fadeToImage(target, src, srcset) {
  let alreadyLoaded = false;

  let img = new Image();
  let oldImages = document.querySelectorAll(`${BACKGROUND_FIGURE} img`);

  img.onload = () => {
    // fix for objectFitImages, which triggers another onload
    if (alreadyLoaded) {
      return;
    }
    alreadyLoaded = true;

    document.querySelector(BACKGROUND_FIGURE).appendChild(img);

    // fade old images
    for (let i = 0; i < oldImages.length; i++) {
      oldImages[i].setAttribute("aria-hidden", true);
    }

    // remove old images
    window.setTimeout(() => {
      for (let i = 0; i < oldImages.length; i++) {
        if (!document.documentElement.contains(oldImages[i])) {
          return;
        }
        removeImage(oldImages[i]);
      }
    }, 1500);

    // wrap inside timeout due to transition bugs
    window.setTimeout(() => {
      img.setAttribute("aria-hidden", false);
      if (!Modernizr.objectfit) {
        window.setTimeout(() => {
          objectFitImages(img);
        }, 0);
      }
    }, 100);

    return;
  };

  img.setAttribute("aria-hidden", true);
  img.srcset = srcset;
  img.src = src;
}

function appendImage(target, src, srcset) {
  let parentTarget = document.querySelector(
    `${LISTING} a[data-milestone="${target}"]`
  ).parentNode;

  // check if the image was already loaded
  let img = parentTarget.querySelector("img");
  if (img) {
    img.setAttribute("aria-hidden", false);
    return;
  }

  let figure = document.createElement("figure");
  parentTarget.appendChild(figure);

  img = document.createElement("img");
  img.setAttribute("aria-hidden", false);
  img.srcset = srcset;
  img.src = src;

  parentTarget.querySelector("figure").appendChild(img);
}

function showMilestone(target) {
  // remove old selected items, but only on 'desktop'
  if (matchesBreakpoint("medium")) {
    document
      .querySelector(`${LISTING} [aria-selected="true"]`)
      .setAttribute("aria-selected", false);
    document
      .querySelector(`${DESCRIPTIONS}[aria-hidden="false"]`)
      .setAttribute("aria-hidden", true);
  }

  // select new items
  let listingItem = document.querySelector(
    `${LISTING} [data-milestone="${target}"]`
  );
  listingItem.setAttribute("aria-selected", true);

  let description = document.querySelector(
    `${DESCRIPTIONS}[data-milestone="${target}"]`
  );
  description.setAttribute("aria-hidden", false);

  if (matchesBreakpoint("medium")) {
    // rebuild position sticky (since height might differ)
    window.setTimeout(() => {
      Stickyfill.refreshAll();
    }, 0);
  }

  // show image
  let imgSrc = listingItem.getAttribute("data-img-src");
  let imgSrcSet = listingItem.getAttribute("data-img-srcset");

  if (!matchesBreakpoint("medium")) {
    appendImage(target, imgSrc, imgSrcSet);
  } else {
    fadeToImage(target, imgSrc, imgSrcSet);
    handleControlButtons();
  }
}

function hideMilestone(target) {
  let listingItem = document.querySelector(
    `${LISTING} [data-milestone="${target}"]`
  );
  listingItem.setAttribute("aria-selected", false);
  listingItem.blur();

  let description = document.querySelector(
    `${DESCRIPTIONS}[data-milestone="${target}"]`
  );
  description.setAttribute("aria-hidden", true);

  let img = document
    .querySelector(`${LISTING} [data-milestone="${target}"]`)
    .parentNode.querySelector("img");
  img.setAttribute("aria-hidden", true);
}

function bindResizeEvent(el) {
  let currentBreakpoint = getCurrentBreakpoint();
  window.addEventListener("resize", resizeThrottler, false);

  let resizeTimeout;
  function resizeThrottler() {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        let newBreakpoint = getCurrentBreakpoint();

        // just reload the page (a bit nasty, but fine for now)
        switch (currentBreakpoint) {
          case "small":
            if (matchesBreakpoint("medium")) {
              window.location.reload(false);
            }
            break;
          case "medium":
          case "large":
          case "extraLarge":
          case "huge":
            if (!matchesBreakpoint("medium")) {
              window.location.reload(false);
            }
            break;
        }
      }, 500);
    }
  }
}

function prepareAndShowInitialMilestones() {
  if (matchesBreakpoint("medium")) {
    // move descriptions from listing item to sticky container
    let stickyContainer = document.querySelector(STICKY);
    let descriptions = document.querySelectorAll(DESCRIPTIONS);
    for (let i = 0; i < descriptions.length; i++) {
      stickyContainer.appendChild(descriptions[i]);
    }
    return;
  }

  // append image of initially selected item
  let selectedListingItem = document.querySelector(
    `${LISTING} [aria-selected="true"]`
  );
  if (selectedListingItem) {
    let target = selectedListingItem.getAttribute("data-milestone");
    let imgSrc = selectedListingItem.getAttribute("data-img-src");
    let imgSrcSet = selectedListingItem.getAttribute("data-img-srcset");
    appendImage(target, imgSrc, imgSrcSet);
  }
}

function getCurrentListingItemIndex(items) {
  for (let i = 0; i < items.length; i++) {
    if (items[i].getAttribute("aria-selected") === "true") {
      return i;
    }
  }
}

function handleControlButtons() {
  let listingItems = document.querySelectorAll(`${LISTING} [data-milestone]`);
  let currentIndex = getCurrentListingItemIndex(listingItems);

  document
    .querySelector('[data-handler="milestonesUp"]')
    .removeAttribute("disabled");
  document
    .querySelector('[data-handler="milestonesDown"]')
    .removeAttribute("disabled");

  if (currentIndex <= 0) {
    document
      .querySelector('[data-handler="milestonesUp"]')
      .setAttribute("disabled", "disabled");
  } else if (currentIndex >= listingItems.length - 1) {
    document
      .querySelector('[data-handler="milestonesDown"]')
      .setAttribute("disabled", "disabled");
  }
}

function goUpDown(direction) {
  if (!matchesBreakpoint("medium")) {
    return;
  }

  let listingItems = document.querySelectorAll(`${LISTING} [data-milestone]`);
  let currentIndex = getCurrentListingItemIndex(listingItems);
  let targetIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

  if (targetIndex < 0 || targetIndex >= listingItems.length) {
    return;
  }

  let target = listingItems[targetIndex].getAttribute("data-milestone");
  showMilestone(target);

  // scroll into view
  let listingItem = document.querySelector(
    `${LISTING} [data-milestone="${target}"]`
  );

  const sweetScroll = new SweetScroll();
  sweetScroll.toElement(document.querySelector(`#${target}-tab`), {
    offset: -document.documentElement.clientHeight / 2,
    duration: 500,
    easing: "easeInOutCubic",
    complete: () => {
      listingItem.blur();
    },
  });
}

function bindKeyboardHandlers() {
  document.addEventListener("keydown", (e) => {
    switch (e.keyCode) {
      case 39: // left
      case 40: // down
        e.preventDefault();
        goUpDown("down");
        break;
      case 38: // up
      case 37: // right
        e.preventDefault();
        goUpDown("up");
        break;
    }
  });
}

export const enhancer = (el) => {
  prepareAndShowInitialMilestones();
  bindKeyboardHandlers();
  bindResizeEvent();
};

export const select = (el, e) => {
  e.preventDefault();
  const target = el.getAttribute("data-milestone");
  if (!target) {
    return;
  }

  if (
    !matchesBreakpoint("medium") &&
    el.getAttribute("aria-selected") === "true"
  ) {
    hideMilestone(target);
    return;
  }
  showMilestone(target);
};

export const up = (el, e) => {
  e.preventDefault();
  el.blur();
  goUpDown("up");
};

export const down = (el, e) => {
  e.preventDefault();
  el.blur();
  goUpDown("down");
};

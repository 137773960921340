/* eslint-disable no-underscore-dangle, no-nested-ternary */

/**
 * Enhance the site-search form.
 *
 * Warning: it is important to reflect user input on the page in a safe way.
 * Currently we sanitize the `query` and `typeFilter` parameters, by transforming
 * them to common HTML entities (`"` will become `&quot`). We don't store them
 * in the state because that will double escape some characters.
 * They're sent escaped to the server, and are added escaped in the template,
 * because the template is renderd via `innerHTML`. Else quotes and tags can break
 * up the rendered inputs and inject the query as HTML.
 *
 * This prevent a reflected XSS attack, e.g. by doing a search query for:
 * `"><IMG SRC=/ onerror="alert(String.fromCharCode(88, 83, 83))"></img>`.
 *
 * Also see:
 * - https://owasp.org/www-community/attacks/xss/#stored-and-reflected-xss-attacks
 * - http://www.websecuritylens.org/using-html-entity-encode-to-mitigate-xss-vulnerability-then-double-check-it/
 *
 * @TODO
 * How about performance?
 * - Page/form is only rendered after `fetch`...
 * - This goes for filter buttons as well, they keep 'hanging' after submit on
 *   slow connections
 * - Should we add a timeout? A spinner?
 */

import URLSearchParams from "@ungap/url-search-params";
import { last } from "@grrr/utils";
import { range, sanitizeHtml, toQueryString } from "./util";
import { trackEvent } from "./gtm-event";

const PAGE_LIMIT = 10;
const SEARCH_PATH = "/search/";

const FORM_ID = "site-search-form";
const RESULTS_SELECTOR = "#content";
const SITE_NAV_SELECTOR = ".js-site-nav";
const RESULT_ANNOUNCER_CLASS = "js-site-search-announcer";

const SEARCH_URL_ATTRIBUTE = "data-search-url";
const CLEAR_FORM_ATTRIBUTE = "data-clear-after-use";

const POST_TYPES = {
  page: "General",
  faq: "FAQ",
  "job-opening": "Job Opening",
  "media-gallery": "Media Gallery",
  milestone: "Milestone",
  podcast: "Podcast",
  "press-release": "Press Release",
  publication: "Scientific Publication",
  post: "Update",
};

/**
 * Singleton history manager.
 * We don't want multiple search forms (header and on results page) competing
 * with history entries and popstate events.
 */
const HistoryManager = {
  isInitialized: false,
  init(popstateCallback) {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    window.addEventListener("popstate", popstateCallback);

    // Check for initial query parameters.
    const searchParams = new URLSearchParams(window.location.search);
    if (window.location.pathname !== SEARCH_PATH) {
      return;
    }
    const queryState = {
      query: searchParams.get("query") || "",
      currentPage: searchParams.get("currentPage") || 1,
      typeFilter: searchParams.get("typeFilter") || "",
    };
    // Call the popstate callback with the artificial state constructed at pageload.
    popstateCallback({ state: queryState });
    this.setPageTitle(queryState);
  },
  pushState(state) {
    const params = {
      query: state.query,
      typeFilter: state.typeFilter,
      currentPage: state.currentPage,
    };
    window.history.pushState(
      params,
      this.getPageTitle(params),
      `${SEARCH_PATH}?${toQueryString(params)}`
    );
    this.setPageTitle(params);
  },
  setPageTitle(params) {
    document.title = this.getPageTitle(params);
  },
  getPageTitle(params) {
    return `Search results for ${params.query} (page ${params.currentPage}) | The Ocean Cleanup`;
  },
};

/**
 * Whether the form is visible.
 */
const isVisible = (form) => form.getAttribute("aria-hidden") !== "true";

/**
 * Clear the form of input and hide it.
 */
const clearForm = (form) => {
  form.elements.q.value = "";
  form.setAttribute("aria-hidden", "true");
};

/**
 * Show/hide the form.
 */
const toggleFormVisibility = (form) => {
  const currValue = form.getAttribute("aria-hidden");
  form.setAttribute("aria-hidden", currValue === "true" ? "false" : "true");
};

/**
 * Get permalink to a result, optionally suffixed by an anchor.
 */
const getResultLink = (result) =>
  `${result._source.permalink}${
    result.matching_section ? `#${result.matching_section}` : ""
  }`;

/**
 * Execute search query.
 * @TODO handle bad responses and timeouts?
 */
const search = (searchApi, query, currentPage, typeFilter) => {
  const params = toQueryString({
    q: sanitizeHtml(query),
    size: PAGE_LIMIT,
    page: currentPage,
    type_filter: typeFilter || "",
  });
  return fetch(`${searchApi}?${params}`).then((x) => x.json());
};

/**
 * Render plural or singular form of a noun. For instance: "result(s)".
 */
const pluralize = (noun, amount) => {
  if (amount && parseInt(amount, 10) === 1) {
    return noun;
  }
  if (last(noun) === "y") {
    return `${noun.slice(0, -1)}ies`;
  }
  return `${noun}s`;
};

/**
 * Get human readable post type label.
 */
const getPostTypeLabel = (type) => POST_TYPES[type];

/**
 * Get human readable post type label in plural form.
 */
const getPostTypeLabelPlural = (type) => {
  const label = POST_TYPES[type];
  return label === "General" ? label : pluralize(label);
};

/**
 * Get excerpt shown below search result.
 */
const getExcerpt = (result) => {
  if (
    result._source.post_type === "media-gallery" &&
    result._source.media_gallery_counts
  ) {
    return Object.entries(result._source.media_gallery_counts).reduce(
      (acc, [key, amount]) =>
        !amount
          ? acc
          : `${acc}${acc ? ", " : ""}${amount} ${pluralize(key, amount)}`,
      ""
    );
  }
  return result.highlight && result.highlight.post_content
    ? result.highlight.post_content[0]
    : "";
};

/**
 * Return the total number of hits. This normalizes a difference in ElasticSearch version where
 * total might be an int, or an object with a value property.
 */
const getTotalHits = (total) =>
  typeof total.value !== "undefined"
    ? getTotalHits(total.value)
    : parseInt(total, 10);

/**
 * Count hits in a certain aggregation.
 */
const countAggregations = (type, aggs) => {
  const typeAgg = aggs.find((x) => x.key === type);
  return typeof typeAgg === "undefined" ? 0 : typeAgg.doc_count;
};

/**
 * Count hits in a all aggregations.
 */
const countTotalAggregations = (aggs) => {
  return aggs.reduce((acc, agg) => acc + agg.doc_count, 0);
};

/**
 * Render search form.
 */
const renderSearchForm = ({ query, typeFilter, searchApi }) => `
  <form class="site-search-form" data-enhancer="siteSearch" ${SEARCH_URL_ATTRIBUTE}="${searchApi}" id="${FORM_ID}">
    <input type="search" name="q" autocomplete="off" autocapitalize="none" value="${sanitizeHtml(
      query
    )}" placeholder="Search for..." required aria-label="Search input">
    <input type="hidden" name="type_filter" value="${sanitizeHtml(
      typeFilter
    )}"/>
    <button type="submit" aria-label="Search"></button>
  </form>
`;

/**
 * Render search header.
 */
const renderHeader = ({
  query,
  typeFilter,
  apis: { search: searchApi },
  results,
}) => `
  <header class="page-header page-header--search" id="page-header">
    <div class="page-header__inner" id="page-header-inner">
      <div class="page-header__title">
        <h2>Search ${results.hits ? "results" : ""}</h2>
        ${renderSearchForm({ query, typeFilter, searchApi })}
      </div>
    </div>
  </header>
`;

/**
 * Render pagination.
 * @TODO handle 'many' pages in the future.
 */
const renderPagination = (state) => {
  if (!state.results.hits) {
    return "";
  }
  const totalHits = getTotalHits(state.results.hits.total);
  if (totalHits <= PAGE_LIMIT) {
    return "";
  }
  const buttons = range(Math.ceil(totalHits / PAGE_LIMIT) + 1, 1).map(
    (n) => `
    <button class="pagination__item" type="button" data-page="${n}" form="${FORM_ID}" data-handler="siteSearchPaginate" aria-current="${
      n === state.currentPage ? "page" : "false"
    }">${n}</button>
  `
  );
  return `
    <nav class="pagination" aria-label="Search pagination">
      <ol role="presenation">
        <li>${buttons.join("</li><li>")}</li>
      </ol>
    </nav>
  `;
};

/**
 * Reander result featured image.
 */
const renderResultImage = (image) => {
  if (image.src && image.srcset) {
    return `
      <div class="search-result__thumbnail">
        <img src="${image.src}" srcset="${image.srcset}" sizes="275px" alt="${
      image.alt || ""
    }"/>
      </div>
    `;
  }
  return ``;
};

/**
 * Render result footer.
 */
const renderResultFooter = (data) => {
  const items = [];
  if (data.podcast_duration) {
    items.push(`
      <span class="search-result__meta-label">${data.podcast_duration}</span>
    `);
  }
  if (data.post_date_label) {
    items.push(
      `<time datetime="${data.post_date}">${data.post_date_label}</time>`
    );
  }
  if (data.update_author) {
    items.push(`<span rel="author">${data.update_author}</span>`);
  }
  if (!items.length) {
    return ``;
  }
  return `
    <footer class="search-result__footer">${items.join("")}</footer>
  `;
};

const renderModifier = (data) => {
  const modifiers = [];
  if (data.post_type === "podcast") {
    modifiers.push("search-result--podcast");
  }
  return modifiers.join("");
};

/**
 * Render search result.
 */
const renderResult = (result) => {
  return `
    <article class="search-result ${renderModifier(result._source)}">
      <a class="search-result__link"
        href="${getResultLink(result)}">
        <div class="search-result__content">
          <span class="search-result__category">${getPostTypeLabel(
            result._source.post_type
          )}</span>
          <h2 class="search-result__title">${result._source.post_title}</h2>
          ${renderResultFooter(result._source)}
          <p class="search-result__excerpt">
            ${getExcerpt(result)}
          </p>
        </div>
        ${renderResultImage(result._source.featured_image)}
      </a>
    </article>
  `;
};

/**
 * Render search filters.
 */
const renderFilterButtons = ({ query, typeFilter, results }) => {
  const aggregations = results.aggregations.types.buckets;
  const renderFilterOption = (type) => {
    const label = getPostTypeLabelPlural(type);
    return `
      <button class="filter-nav__link" type="button" data-handler="siteSearchFilter" data-filter-type="${type}" form="${FORM_ID}" aria-current="${
      type === typeFilter ? "true" : "false"
    }" aria-label="Filter results on ${label}">
        <span data-count="${countAggregations(
          type,
          aggregations
        )}">${label}</span>
      </button>
    `;
  };
  const options = Object.keys(POST_TYPES).filter((type) =>
    countAggregations(type, aggregations)
  );
  return `
    <div class="filter-nav">
      <div class="filter-nav__scroller">
        <nav class="filter-nav__inner" aria-label="Search filters">
          <ul class="filter-nav__list" role="presenation">
            <li class="filter-nav__item">
              <button class="filter-nav__link" type="button" data-handler="siteSearchFilter" data-filter-type="" form="${FORM_ID}" aria-current="${
    typeFilter ? "false" : "true"
  }" aria-label="Show all results"><span data-count="${countTotalAggregations(
    aggregations
  )}">All</span></button>
            </li>
            <li class="filter-nav__item">${options
              .map(renderFilterOption)
              .join(`</li><li class="filter-nav__item">`)}</li>
          </ul>
        </nav>
      </div>
    </div>
  `;
};

/**
 * Render a search page content based on results.
 */
const renderSearchPageContent = (state) => {
  if (state.hasError) {
    return `
      <div class="site-search__message">
        <div class="site-search__warning" role="alert">
          <p>Sorry, something went wrong with your request. Please try again <span aria-hidden="true">☝️</span></p>
        </div>
      </div>
    `;
  }
  if (!state.query) {
    return `
      <div class="site-search__message">
        <div class="site-search__notice" role="alert">
          <p>Please enter a search query <span aria-hidden="true">☝️</span></p>
        </div>
      </div>
    `;
  }
  if (
    !state.results.hits ||
    !state.results.hits.hits ||
    !state.results.hits.hits.length
  ) {
    return `
      <div class="site-search__message">
        <div class="site-search__notice" role="alert">
          <p>Sorry, no results found for <strong>${sanitizeHtml(
            state.query
          )}</strong>. Please try again <span aria-hidden="true">☝️</span></p>
        </div>
      </div>
    `;
  }
  const totalHits = state.results.hits.total.value;
  return `
    <div class="site-search__filters">
      ${renderFilterButtons(state)}
    </div>
    <p class="sr-only ${RESULT_ANNOUNCER_CLASS}" tabindex="0">${totalHits} ${pluralize(
    "Result",
    totalHits
  )} for "${sanitizeHtml(state.query)}"</p>
    <div class="site-search__results">
      <ul aria-label="List with search results">
        <li>${state.results.hits.hits.map(renderResult).join("</li><li>")}</li>
      </ul>
    </div>
  `;
};

/**
 * Render a full search page, including header, search form, results and pagination.
 */
const renderSearchPage = (state) => `
  <section class="site-search">
    ${renderHeader(state)}
    <div class="site-search__inner">
      ${renderSearchPageContent(state)}
    </div>
    <div class="site-search__pagination">
      ${renderPagination(state)}
    </div>
  </section>
`;

const appendSearchPage = (state) => {
  // Search might be executed from a scrolled-down position (due to sticky nav).
  window.scrollTo(0, 0);

  // Render results.
  state.resultsContainer.innerHTML = renderSearchPage(state);

  // Enhance search form.
  const pageForm = document.forms[FORM_ID];
  state.enhanceForm(pageForm);

  // Focus on result announcement or form when no results are found.
  // No results will be announced by an `alert`.
  requestAnimationFrame(() => {
    const resultAnnouncer = state.resultsContainer.querySelector(
      `.${RESULT_ANNOUNCER_CLASS}`
    );
    if (resultAnnouncer) {
      resultAnnouncer.focus();
    } else {
      pageForm.elements.q.focus();
    }
  });
};

/**
 * Redraw search results page based on the new state.
 */
const updateSearchResults = (state) => {
  if (!state.query) {
    appendSearchPage(state);
    return;
  }
  search(state.apis.search, state.query, state.currentPage, state.typeFilter)
    .then((results) => {
      // Hide the form, make way for the search results page.
      if (state.clearAfterUse) {
        clearForm(state.form);
      }
      appendSearchPage({ ...state, results: results.results });
    })
    .catch((error) => {
      appendSearchPage({ ...state, results: {}, hasError: true });
      console.warn(error);
    });
};

/**
 * Form enhancer.
 */
export const enhancer = (form) => {
  const clearAfterUse = form.getAttribute(CLEAR_FORM_ATTRIBUTE) === "true";
  const resultsContainer = document.querySelector(RESULTS_SELECTOR);
  if (!resultsContainer) {
    console.warn(`Results container "${RESULTS_SELECTOR}" not found.`);
    return;
  }

  // Initially hide the element.
  if (clearAfterUse) {
    clearForm(form);
  }

  const state = {
    form,
    resultsContainer,
    apis: {
      search: form.getAttribute(SEARCH_URL_ATTRIBUTE),
    },
    query: "",
    typeFilter: "",
    currentPage: 1,
    results: {},
    enhanceForm: enhancer,
    hasError: false,
    clearAfterUse,
  };

  // Catch search requests.
  form.addEventListener("submit", (e) => {
    e.preventDefault();

    state.query = form.elements.q.value;
    state.typeFilter = "";
    state.currentPage = 1;
    state.hasError = false;

    updateSearchResults(state);

    // Record state in history.
    HistoryManager.pushState(state);

    // Send event to Google to register search query.
    // @TODO When a bigger analytics implementation is being developed, this
    // should be refactored.
    trackEvent({
      category: "Search",
      action: "submit",
      label: state.query,
    });

    // Remove current state from main navigation.
    const activeNavItem = document.querySelector(
      `${SITE_NAV_SELECTOR} [aria-current="page"]`
    );
    if (activeNavItem) {
      activeNavItem.setAttribute("aria-current", "false");
    }
  });

  // Paginate function, called from the paginateHandler below.
  form.paginate = (index) => {
    state.query = form.elements.q.value;
    state.typeFilter = form.elements.type_filter.value;
    state.currentPage = index;

    updateSearchResults(state);

    // Record state in history.
    HistoryManager.pushState(state);
  };

  // Filter function, called from the filterHandler below.
  form.filter = (type) => {
    state.query = form.elements.q.value;
    state.typeFilter = type;
    state.currentPage = 1;

    updateSearchResults(state);

    // Record state in history.
    HistoryManager.pushState(state);
  };

  // Record popstate listener.
  HistoryManager.init((e) => {
    if (!e.state) {
      return;
    }
    state.query = e.state.query;
    state.typeFilter = e.state.typeFilter;
    state.currentPage = parseInt(e.state.currentPage, 10);

    updateSearchResults(state);
  });
};

/**
 * Search form escape handler (for form in `site-header`).
 */
const formKeyUpHandler = (e) => {
  const isEscape = (e.key && e.key === "Escape") || e.keyCode === 27;
  if (isEscape) {
    const form = e.currentTarget;
    toggleFormVisibility(form);
    form.removeEventListener("keyup", formKeyUpHandler);
  }
};

/**
 * Toggle button handler (for toggle in `site-header`).
 */
export const toggleHandler = (button, e) => {
  e.preventDefault();
  const form = document.querySelector(button.getAttribute("href"));
  toggleFormVisibility(form);
  if (isVisible(form)) {
    form.addEventListener("transitionend", () => form.elements.q.focus());
    form.addEventListener("keyup", formKeyUpHandler);
  } else {
    form.removeEventListener("keyup", formKeyUpHandler);
  }
};

/**
 * Paginate button handler.
 */
export const paginateHandler = (button, e) => {
  e.preventDefault();
  button.form.paginate(parseInt(button.getAttribute("data-page"), 10));
};

/**
 * Filter button handler.
 */
export const filterHandler = (button, e) => {
  e.preventDefault();
  const form =
    button.form || document.getElementById(button.getAttribute("form"));
  form.filter(button.getAttribute("data-filter-type"));
};

/* eslint-disable */

/**
 * Load Twitter feed
 * @see: https://dev.twitter.com/web/embedded-timelines/user
 */

export const enhancer = (el) => {
  !(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0],
      p = /^http:/.test(d.location) ? "http" : "https";
    if (!d.getElementById(id)) {
      js = d.createElement(s);
      js.id = id;
      js.src = p + "://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  })(document, "script", "twitter-wjs");
};

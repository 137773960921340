/* eslint-disable no-continue, no-restricted-globals, no-use-before-define, prefer-destructuring */

/**
 * PhotoSwipe. Assets are loaded externally due to size.
 *
 * Initial code take form: http://photoswipe.com/documentation/getting-started.html
 * Also see: http://photoswipe.com/
 */

import { selfOrClosest, waitForGlobal } from "./util";

const GALLERY_INDEX_ATTRIBUTE = "data-pswp-uid";
const GALLERY_ITEM_SELECTOR = ".js-gallery-item";
const DOWNLOAD_LINK_SELECTOR = ".js-download-link";

let galleryIndex = 1;

const PhotoSwipeGallery = (PhotoSwipe, PhotoSwipeUI) => {
  // Parse picture index and gallery index from URL (#&pid=1&gid=2)
  const photoswipeParseHash = () => {
    const hash = window.location.hash.substring(1);
    const params = {};
    if (hash.length < 5) {
      return params;
    }
    const vars = hash.split("&");
    for (let i = 0; i < vars.length; i += 1) {
      if (!vars[i]) {
        continue;
      }
      const pair = vars[i].split("=");
      if (pair.length < 2) {
        continue;
      }
      params[pair[0]] = pair[1];
    }
    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }
    return params;
  };

  const parseThumbnailElements = (items) => {
    const parsedItems = [...items].map((item) => {
      const anchor = item.querySelector("a[data-size]");
      const caption = item.querySelector("figcaption");
      const image = item.querySelector("img");
      const size = anchor.getAttribute("data-size").split("x");

      return {
        el: item,
        src: anchor.getAttribute("href"),
        msrc: image.getAttribute("src"),
        w: parseInt(size[0], 10) || 0,
        h: parseInt(size[1], 10) || 0,
        raw_url: anchor.getAttribute("data-original-image"),
        title: caption ? caption.textContent : "",
      };
    });
    return parsedItems;
  };

  // Triggers when user clicks on thumbnail
  const clickHandler = (container) => (e) => {
    const target = e.target || e.srcElement;
    if (selfOrClosest(target, DOWNLOAD_LINK_SELECTOR)) {
      return;
    }

    const clickedItem = selfOrClosest(target, GALLERY_ITEM_SELECTOR);

    if (!clickedItem) {
      return;
    }
    e.preventDefault();

    const clickedGallery = container;
    const items = container.querySelectorAll(GALLERY_ITEM_SELECTOR);
    const index = [...items].findIndex((item) => item === clickedItem);

    openPhotoSwipe({ index, galleryElement: clickedGallery });
  };

  const updateDownloadButton = (pswpGallery) => {
    const downloadButton = document.querySelector(".js-pswp-download");
    if (pswpGallery.currItem && pswpGallery.currItem.raw_url) {
      downloadButton.setAttribute("href", pswpGallery.currItem.raw_url);
      downloadButton.style.display = "block";
    } else {
      downloadButton.style.display = "none";
    }
  };

  const getGalleryItems = (galleryElement) =>
    galleryElement.querySelectorAll(GALLERY_ITEM_SELECTOR);

  const openPhotoSwipe = ({ index, galleryElement, fromURL }) => {
    const pswpDOMElement = document.querySelector(".pswp");
    const items = parseThumbnailElements(getGalleryItems(galleryElement));
    const options = {
      galleryUID: galleryElement.getAttribute("data-pswp-uid"),
      index: fromURL ? parseInt(index, 10) - 1 : parseInt(index, 10),
      getThumbBoundsFn: false,
      showHideOpacity: true,
      hideAnimationDuration: 0,
      showAnimationDuration: 0,
      closeOnScroll: false,
      shareEl: true,
      downloadEl: true,
      shareButtons: [
        {
          id: "facebook",
          label: "Share on Facebook",
          url: "https://www.facebook.com/sharer/sharer.php?u={{url}}",
        },
        {
          id: "twitter",
          label: "Tweet",
          url: "https://twitter.com/intent/tweet?text={{text}}&url={{url}}",
        },
        {
          id: "pinterest",
          label: "Pin it",
          url:
            "http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}",
        },
      ],
      isClickableElement: (el) => {
        return (
          el.tagName.toUpperCase() === "VIDEO" ||
          el.tagName.toUpperCase() === "A"
        );
      },
    };

    // Exit if index not found
    if (isNaN(options.index)) {
      return;
    }

    // Pass data to PhotoSwipe and initialize it
    const photoSwipe = new PhotoSwipe(
      pswpDOMElement,
      PhotoSwipeUI,
      items,
      options
    );
    photoSwipe.init();

    // Update download anchor/link on init and on beforeChange
    updateDownloadButton(photoSwipe);
    photoSwipe.listen("beforeChange", (e) => {
      updateDownloadButton(photoSwipe);
    });
  };

  return {
    init(container) {
      const currentIndex = galleryIndex;
      container.setAttribute(GALLERY_INDEX_ATTRIBUTE, currentIndex);
      container.addEventListener("click", clickHandler(container));

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      const hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid && hashData.gid === currentIndex) {
        openPhotoSwipe({
          index: hashData.pid,
          galleryElement: container,
          fromURL: true,
        });
      }

      galleryIndex += 1;
    },
  };
};

export const enhancer = (el) => {
  waitForGlobal("PHOTOSWIPE_LOADED", 100).then(() => {
    const gallery = PhotoSwipeGallery(
      window.PhotoSwipe,
      window.PhotoSwipeUI_Default
    );
    gallery.init(el);
  });
};

import { htmlToElement, uuid } from "@grrr/utils";

const toggle = ({ container, button }) => {
  const isHidden = button.getAttribute("aria-expanded") === "false";
  container.hidden = !isHidden;
  button.setAttribute("aria-expanded", !!isHidden);
  button.querySelector("span").textContent = isHidden
    ? "Close info"
    : "Read more";
  button.blur();
};

export const enhancer = (button) => {
  const parent = button.parentNode;

  // Fetch all hidden nodes.
  const children = [...parent.children];
  const buttonIndex = children.findIndex((node) => node === button);
  const targetNodes = children.filter((node, index) => index > buttonIndex);

  // Create container for hidden nodes and append them.
  const containerId = `expand-button-container-${uuid()}`;
  const container = htmlToElement(`
    <div id="${containerId}" hidden style="margin-bottom: 1em;"></div>
  `);
  targetNodes.forEach((node) => container.appendChild(node));

  // Append the container before the button.
  parent.insertBefore(container, button);
  button.setAttribute("aria-controls", containerId);

  // Add click listener for the button.
  button.addEventListener("click", (e) => {
    toggle({ container, button });
    e.preventDefault();
  });
};

const SDK_OPTIONS = {
  version: "v2.12",
  xfbml: true,
};

const loadSdk = () => {
  window.fbAsyncInit = function () {
    window.FB.init(SDK_OPTIONS);
  };
  (function (d, s, id) {
    let js = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

export const loadOrReloadSdk = () => {
  if (typeof FB !== "undefined") {
    window.FB.init(SDK_OPTIONS);
  } else {
    loadSdk();
  }
};

import { subscribe } from "./observer";

/**
 * Called by Observer.
 * Updates the HTML with the current index.
 */
const watch = (singleEl) => ({ index }) => {
  singleEl.innerHTML = index;
};

export const enhancer = (element) => {
  const singleSelector = element.getAttribute("data-active-time-single");
  const observerSubject = element.getAttribute("data-observer-subject");

  const singleEl = element.querySelector(singleSelector);

  subscribe(observerSubject, watch(singleEl));
};

/* eslint-disable */

export const getScrollPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
});

export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 - rect.height &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        rect.height
  );
};

export const isFullyInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const next = (el) => {
  let nextSibling = el.nextSibling;
  while (nextSibling && nextSibling.nodeType !== 1) {
    nextSibling = nextSibling.nextSibling;
  }
  return nextSibling;
};

export const previous = (el) => {
  let previousSibling = el.previousSibling;
  while (previousSibling && previousSibling.nodeType !== 1) {
    previousSibling = previousSibling.previousSibling;
  }
  return previousSibling;
};

export const closest = (el, selector) => {
  var matchesFn;

  // find vendor prefix
  [
    "matches",
    "webkitMatchesSelector",
    "mozMatchesSelector",
    "msMatchesSelector",
    "oMatchesSelector",
  ].some(function (fn) {
    if (typeof document.body[fn] === "function") {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  // traverse parents
  while (el !== null) {
    let parent = el.parentElement;
    if (parent !== null && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
};

export const selfOrClosest = (el, selector) => {
  var matchesFn;

  // find vendor prefix
  [
    "matches",
    "webkitMatchesSelector",
    "mozMatchesSelector",
    "msMatchesSelector",
    "oMatchesSelector",
  ].some(function (fn) {
    if (typeof document.body[fn] === "function") {
      matchesFn = fn;
      return true;
    }
    return false;
  });
  // check if it's the element itself
  if (el !== null && el[matchesFn](selector)) {
    return el;
  }
  // traverse parents
  while (el !== null) {
    let parent = el.parentElement;
    if (parent !== null && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
};

export const head = (a) => {
  return a[0];
};

export const map = (a, fn) => {
  Array.prototype.map.call(a, fn);
};

export const forEach = (a, fn) => {
  Array.prototype.forEach.call(a, fn);
};

export const filter = (a, fn) => {
  return Array.prototype.filter.call(a, fn);
};

export const not = (fn) => {
  return (e) => {
    return !fn(e);
  };
};

export const objectByKey = (arr, key) => {
  return arr.reduce((prev, curr) => {
    if (typeof curr[key] === "undefined") {
      return prev;
    }
    prev[curr[key]] = curr;
    return prev;
  }, {});
};

export const timestampToMidnight = (timestamp) => {
  let date = new Date(timestamp * 1000);
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
};

export const getAge = (birthDate) => {
  let today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn(...args);
    }, delay);
  };
};

export const throttle = (fn, delay) => {
  let timer = null;
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        fn.apply(this, args);
        timer = null;
      }, delay);
    }
  };
};

export const parseJSON = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.warn("This JSON does not appear to be valied");
    console.error(e);
    return undefined;
  }
};

export const generateUuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const htmlToElement = (html) => {
  const placeholder = document.createElement("div");
  placeholder.innerHTML = html;
  return placeholder.children.length === 1
    ? placeholder.children[0]
    : placeholder.children;
};

export const dedup = (xs) => {
  return xs.reduce((ys, x) => (ys.includes(x) ? ys : ys.concat([x])), []);
};

export const tap = (f) => {
  return (x) => {
    f(x);
    return x;
  };
};

export const getTemplateContent = (template) => {
  if ("content" in template) {
    return template.content;
  }

  const children = template.childNodes;
  const fragment = document.createDocumentFragment();

  while (children[0]) {
    fragment.appendChild(children[0]);
  }

  return fragment;
};

export const waitForGlobal = (target, interval = 500, maxWaitTime = 2000) => {
  return new Promise((resolve, reject) => {
    let waitingTime = 0;
    const check = () => {
      if (target in window) {
        resolve(window[target]);
      } else {
        waitingTime += interval;
        if (waitingTime > maxWaitTime) {
          reject(
            new Error(
              `Global ${target} not loaded within the time limit of ${maxWaitTime}ms.`
            )
          );
          return;
        }
        window.setTimeout(check, interval);
      }
    };
    check();
  });
};

/**
 * Converts a UTC timestamp to a relative time label.
 */
export const getRelativeTimeLabel = (date) => {
  if (
    typeof Intl !== "object" ||
    typeof Intl.RelativeTimeFormat !== "function"
  ) {
    return "";
  }
  const minutes = Math.round((Date.parse(date) - Date.now()) / (1000 * 60));
  const formatter = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  if (minutes < -1920) {
    // More than 1,5 day ago
    return formatter.format(Math.round(minutes / (60 * 24)), "day");
  }
  if (minutes > -120) {
    // Less than 2 hours ago
    return formatter.format(minutes, "minute");
  }
  return formatter.format(Math.round(minutes / 60), "hour");
};

/**
 * Converts a date into September 27, 2021 format.
 */
export const getDisplayDate = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

/**
 * Convert an integer to a thousands-separated label.
 */
export const getIntegerLabel = (int) => {
  if (
    typeof Intl !== "object" ||
    typeof Intl.NumberFormat !== "function" ||
    !int
  ) {
    return int;
  }
  const formatter = new Intl.NumberFormat("en-EN");
  return formatter.format(parseInt(int, 10));
};

export const prefersNoMotion = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
).matches;

export const prefersToSaveData =
  navigator.connection && navigator.connection.saveData;

export const range = (max, n = 0) =>
  n >= max ? [] : [n].concat(range(max, n + 1));

export const toQueryString = (object) =>
  Object.entries(object).reduce((qs, [key, value]) => {
    return `${qs}${qs.length ? "&" : ""}${key}=${encodeURIComponent(value)}`;
  }, "");

export const dispatchEvent = (el, events = []) => {
  if (typeof Event !== "function") {
    return;
  }
  events.forEach((event) =>
    el.dispatchEvent(new Event(event, { bubbles: true }))
  );
};

/**
 * Escape common HTML entities.
 */
export const sanitizeHtml = (string) => {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "`": "&grave;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'`/]/gi;
  return string.replace(reg, (match) => map[match]);
};

export const supportsIntersectionObserver = () =>
  "IntersectionObserver" in window;

export const supportsMutationObserver = () => "MutationObserver" in window;

/**
 * Checks if you are using Safari as a browser.
 * Don't use this utility unless there is no other option!
 * A better solution would be to check if a specific feature is supported
 * instead of a specific browser is being used.
 */
export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window.safari || window.safari.pushNotification)
  );
};

/**
 * Hash a string with SHA-256
 *
 * Source: https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
 * Crypto documentation: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
export const hash = (string) => {
  // Bail out of hashing when the Crypo API isn't present.
  if (!crypto) {
    return new Promise((resolve) => {
      return resolve(string);
    });
  }

  // String to buffer (so crypto API can use it).
  const utf8 = new TextEncoder().encode(string);
  // Hash
  return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    // Buffer to Array (so we can use it again).
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // Array to string (so we can send it to another server as plain text).
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  });
};

import { SourcesMapMapbox, MARKER_CLICK_EVENT } from "./sources-map-mapbox";
import { SourcesMapOverlays } from "./sources-map-overlays";
import { SourcesMapPopup } from "./sources-map-popup";
import { matchesBreakpoint } from "./responsive";
import { waitForGlobal } from "./util";

const MAPBOX_TOKEN_ATTRIBUTE = "data-mapbox-token";
const SUPPORT_WARNING_SELECTOR = ".js-support-warning";
const MAP_SELECTOR = ".js-map";

const SourcesMap = (container) => {
  const token = container.getAttribute(MAPBOX_TOKEN_ATTRIBUTE);

  const sourcesMapOverlays = SourcesMapOverlays(container);

  const markerClickHandler = (e) => {
    if (!matchesBreakpoint("medium") || sourcesMapOverlays.hasInteracted()) {
      sourcesMapOverlays.closeAllOverlays();
    } else {
      sourcesMapOverlays.handleFirstClick();
    }
  };

  const initMapbox = (mapboxgl) => {
    // Show a warning when WebGL is not supported.
    if (!mapboxgl.supported()) {
      const warning = document.querySelector(SUPPORT_WARNING_SELECTOR);
      warning.setAttribute("aria-hidden", "false");
    }

    // Construct popup.
    const sourcesMapPopup = new SourcesMapPopup({
      content: {
        metadata: container.getAttribute("data-popup-content-metadata"),
        sharing: container.getAttribute("data-popup-content-sharing"),
      },
      sharingOptions: {
        link: container.getAttribute("data-share-link"),
        title: container.getAttribute("data-share-title"),
        description: container.getAttribute("data-share-description"),
      },
    });

    // Construct Mapbox.
    const sourcesMapMapbox = new SourcesMapMapbox({
      container: container.querySelector(MAP_SELECTOR),
      popup: sourcesMapPopup,
      mapboxgl,
      token,
    });

    // Initialize Mapbox interactivity.
    sourcesMapMapbox.init();

    // Close all overlays when marker is clicked.
    sourcesMapMapbox.on(MARKER_CLICK_EVENT, markerClickHandler);

    // Expose map for easier debugging.
    window.SOURCES_MAP = sourcesMapMapbox.getMap();
  };

  return {
    init() {
      // Initialize the overlay UI early.
      sourcesMapOverlays.init();
      // Wait until the external `mapboxgl` is loaded.
      waitForGlobal("mapboxgl", 100).then(initMapbox);
    },
  };
};

export const enhancer = (container) => {
  const sourcesMap = SourcesMap(container);
  sourcesMap.init();
};

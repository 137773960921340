import { trapFocus } from "@grrr/utils";

export const enhancer = (element) => {
  let focusTrap = null;
  let isExpanded = false;

  const body = document.querySelector("body");
  const nav = document.querySelector(".js-brandguide-navigation");

  const fns = {
    handleKeyUp: (event) => {
      if (event.code !== "Escape") {
        return;
      }
      isExpanded = false;
      fns.close();
    },

    open: () => {
      nav.classList.add("data-target-class", "is-expanded");
      body.setAttribute("data-brandguide-navigation-scroll-disabled", "true");
      window.addEventListener("keyup", fns.handleKeyUp);
      element.setAttribute("aria-expanded", "true");

      if (focusTrap) {
        focusTrap.retrap();
      } else {
        focusTrap = trapFocus(nav);
      }
    },

    close: () => {
      nav.classList.remove("data-target-class", "is-expanded");
      body.setAttribute("data-brandguide-navigation-scroll-disabled", "false");
      window.removeEventListener("keyup", fns.handleKeyUp);
      element.setAttribute("aria-expanded", "false");

      if (focusTrap) {
        focusTrap.release();
      }
    },
  };

  const handleClick = () => {
    isExpanded = !isExpanded;
    if (isExpanded) {
      fns.open();
    } else {
      fns.close();
    }
  };

  element.addEventListener("click", handleClick);
};

import { publish, subscribe } from "./observer";
import { sunglassesUpdate, sunglassesClose } from "./observer-subjects";

/**
 * Called by observer
 *
 * Set new active id for sunglasses list items
 */
const handleUpdate = (element, elementIndex) => ({ index }) => {
  element.setAttribute("data-active", index === elementIndex);
};

/**
 * Called by observer
 *
 * Set new active id for sunglasses hot-spots
 */
const handleUpdateTrigger = (element) => ({ index }) => {
  element.setAttribute("data-active-id", index);
  element.setAttribute("data-inactive", "false");
};
/**
 * Called by observer
 *
 * Set inactive state to sunglasses hot-spots
 */
const handleClose = (element) => () => {
  element.setAttribute("data-inactive", "true");
};

/**
 * Click on a hot-spot
 * Notifies through the observer
 */
const handleClick = (elementIdex) => (event) => {
  publish(sunglassesUpdate, { index: elementIdex });
};

/**
 * Sunglasses info
 */
export const enhancer = (element) => {
  subscribe(
    sunglassesUpdate,
    handleUpdate(element, element.getAttribute("data-target-id"))
  );
};

/**
 * Trigger
 */
export const trigger = (element) => {
  element.addEventListener(
    "click",
    handleClick(element.getAttribute("data-trigger-id"))
  );

  subscribe(sunglassesUpdate, handleUpdateTrigger(element));
  subscribe(sunglassesClose, handleClose(element));
};

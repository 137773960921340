import cleanString from "../utils/clean-string";
import { publish, subscribe } from "./observer";
import {
  updateInPageNavigationActiveItem,
  updateInPageNavigationState,
  closeInPageNavigation,
} from "./observer-subjects";

/**
 * Add click handling to each in-page navigation item.
 * Add observer to each item to handle the current item.
 */
export const enhancer = (element) => {
  const title = element.getAttribute("data-title");

  const headings = Array.from(document.querySelectorAll("h2, h3, h4, h5, h6"));
  const match = headings.find((heading) => {
    return cleanString(heading.innerText) === cleanString(title);
  });

  if (!match) return;

  const id = match.getAttribute("id")
    ? match.getAttribute("id")
    : cleanString(title);

  match.setAttribute("id", id);
  element.setAttribute("href", `#${id}`);

  const handleClick = (event) => {
    // Prevent default behavior to ensure the scroll action is performed to the center of the page, not the top.
    event.preventDefault();
    // Scroll to the point where the heading is within the center of the screen.
    match.scrollIntoView({ behavior: "smooth", block: "center" });

    // Notify to close the navigation.
    publish(closeInPageNavigation);

    // Manually set id to hash in url (because we use event.preventDefault()).
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, `#${id}`);
  };

  const handleUpdate = (activeItem) => {
    const isActive = activeItem.getAttribute("id") === cleanString(title);

    element.setAttribute("data-active", isActive);
    element.setAttribute("aria-current", isActive);
  };

  const handleStateUpdate = ({ isClosed }) => {
    element.setAttribute("tabindex", isClosed ? "-1" : "0");
  };

  /**
   * Close the menu when the first or last focusable element loses focus and
   * doesn't apply focus on another element from the same list.
   */
  const handleBlur = (event) => {
    // Check if the new target is an in-page navigation item.
    const newTargetsEnhancer = event.relatedTarget.getAttribute(
      "data-enhancer"
    );
    if (
      newTargetsEnhancer &&
      newTargetsEnhancer.includes("inPageNavigationItem")
    ) {
      return;
    }

    // Close in-page navigation.
    publish(closeInPageNavigation);
  };

  element.addEventListener("click", handleClick);
  element.addEventListener("blur", handleBlur);
  subscribe(updateInPageNavigationActiveItem, handleUpdate);
  subscribe(updateInPageNavigationState, handleStateUpdate);
};

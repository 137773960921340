import { enhance } from "@grrr/hansel";
import { enhancer as newsletterForm } from "./newsletter-form";

/**
 * Add HTML after delay.
 * Used for enabling a form after a given time to prevent bot submissions.
 */
export const enhancer = (element) => {
  const html = element.getAttribute("data-html");
  const target = element.querySelector(".js-target");

  setTimeout(() => {
    target.innerHTML = html;

    enhance(target, { newsletterForm });
  }, 1000);
};

const mapConfig = {
  container: "map",
  style: "mapbox://styles/theoceancleanup/ckbqn7x1y5fxh1ilmj6pzk52u",
  logoPosition: "bottom-left",
  center: [17, 20],
  attributionControl: false,
  renderWorldCopies: true,
  maxZoom: 12,
  zoom: 0,
};

/**
 * Create and configure the default MapBox map.
 * Returns configured instance of the map.
 */
export const createDefaultMap = (
  { mapboxgl, token, controlPosition = "top-right" },
  configOverwrite = {}
) => {
  mapboxgl.accessToken = token;

  // Create new instance of the map.
  const map = new mapboxgl.Map({ ...mapConfig, ...configOverwrite })
    .addControl(new mapboxgl.AttributionControl({ compact: false }))
    .addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true,
      }),
      controlPosition
    );

  // Return configured instance of the map.
  return map;
};

import { subscribe } from "../observer";
import {
  trashTrackerResetData,
  trashTrackerResetForm,
  trashTrackerError,
} from "../observer-subjects";

/**
 * Called by observer.
 * Hide error text.
 */
const hide = (element) => () => {
  element.setAttribute("data-show", false);
  element.setAttribute("aria-hidden", true);
};

/**
 * Called by observer.
 * Show/hide error text.
 */
const handleError = (element, titleElement, textElement) => ({
  showError,
  title,
  text,
}) => {
  // Set provided content or use initial content.
  if (title && text) {
    titleElement.innerHTML = title;
    textElement.innerHTML = text;
  } else {
    titleElement.innerHTML = titleElement.getAttribute("data-initial-value");
    textElement.innerHTML = textElement.getAttribute("data-initial-value");
  }

  element.setAttribute("data-show", showError);
  element.setAttribute("aria-hidden", !showError);
};

export const enhancer = (element) => {
  const titleElement = element.querySelector(".js-title");
  const textElement = element.querySelector(".js-text");

  subscribe(trashTrackerResetData, hide(element));
  subscribe(trashTrackerResetForm, hide(element));
  subscribe(trashTrackerError, handleError(element, titleElement, textElement));
};

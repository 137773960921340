/**
 * Called by event listener.
 * Update state of trigger/target based on the value of the aria-hidden property.
 */
const handleClick = (trigger, target) => (event) => {
  event.preventDefault();

  // Get current state from the DOM.
  const isOpen = target.getAttribute("aria-hidden") !== "true";

  // Update the state with the opposite value.
  trigger.setAttribute("aria-pressed", !isOpen);
  target.setAttribute("aria-hidden", isOpen);
};

/**
 * Simple toggle for a description.
 */
export const enhancer = (element) => {
  const trigger = element.querySelector(
    element.getAttribute("data-trigger-selector")
  );
  const target = element.querySelector(
    element.getAttribute("data-target-selector")
  );

  // Set default state.
  target.setAttribute("aria-hidden", "true");

  trigger.addEventListener("click", handleClick(trigger, target));
};

import { debounce, htmlToElement, parseJson } from "@grrr/utils";
import { matchesBreakpoint } from "./responsive";
import { subscribe } from "./observer";
import { teamMember } from "./observer-subjects";

const ITEM_SELECTOR = ".js-item";
const BIO_SELECTOR = ".js-active-bio";
const TRIGGER_SELECTOR = ".new-team-member-list__trigger--is-active";

const CONTENT_ATTRIBUTE = "data-content";
const INDEX_ATTRIBUTE = "data-index";
const PREVIEW_ATTRIBUTE = "data-preview";

const NewTeamMemberList = (container) => {
  const items = [...container.querySelectorAll(ITEM_SELECTOR)];

  let windowWidth = window.innerWidth;

  const createBioNode = ({
    index,
    image,
    name,
    position,
    description,
    links,
    indicatorPosition,
  }) => {
    const aside = links.length
      ? `<aside class="team-member-list__bio-aside">
    ${links
      .map(
        (link) =>
          `<a  class="team-member-list__social-link" href="${link.url}">${link.label}</a>`
      )
      .reduce((accumulator, needle) => `${accumulator} ${needle}`, "")}</aside>`
      : "";

    return htmlToElement(`
    <li class="new-team-member-list__bio-wrapper js-active-bio" data-index="${index}" tabindex="0">
      <div class="new-team-member-list__bio-indicator" style="left: ${indicatorPosition}px;"></div>
      <article class="new-team-member-list__bio">
        <figure class="new-team-member-list__figure">
          ${image}
        </figure>
        <div class="new-team-member-list__information">
          <header class="new-team-member-list__header">
            <h1 class="new-team-member-list__title">${name}</h1>
            <span class="new-team-member-list__position">${position}</span>
          </header>
          ${description || "No description added"}
          ${aside}
        </div>
        <button class="new-team-member-list__bio-close" aria-label="Close biography">
          <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <g fill="none" fill-rule="evenodd" stroke-linecap="square" stroke-width="2">
              <path d="M13.607 2.54L2.335 13.81M13.834 14.098L2.049 2.313"/>
            </g>
          </svg>
        </button>
      </article>
    </li>
  `);
  };

  const isEscapeKey = (e) => (e.key && e.key === "Escape") || e.keyCode === 27;

  /**
   * Get parsed JSON content for the given item.
   */
  const getContentData = (el) => parseJson(el.getAttribute(CONTENT_ATTRIBUTE));

  /**
   * Calculate the amount of columns the grid has.
   */
  const calculateColumnCount = (index) => {
    const coordinates = items.map((item) => {
      return item.getBoundingClientRect().y;
    });

    const currentCoordinate = coordinates[index];

    const currentCoordinateOccurence = coordinates.filter(
      (v) => v === currentCoordinate
    ).length;

    return currentCoordinateOccurence;
  };

  /**
   * Calculate the horizontal center of an element.
   */
  const calculateHorizontalCenter = (el) => {
    const bounds = el.getBoundingClientRect();

    const containerWidth = container.getBoundingClientRect().width;
    const viewportWidth = window.innerWidth;

    const elementCenter = (bounds.left + bounds.right) / 2;
    const leftOutermargin = (viewportWidth - containerWidth) / 2;
    const blockPaddingLarge = 40;
    const halfArrowWithSmall = 15;
    const halfArrowWithLarge = 20;

    if (matchesBreakpoint("large")) {
      return (
        elementCenter - leftOutermargin - blockPaddingLarge - halfArrowWithLarge
      );
    }

    if (matchesBreakpoint("medium")) {
      return elementCenter - halfArrowWithLarge - blockPaddingLarge;
    }

    return elementCenter - halfArrowWithSmall;
  };

  /**
   * Calculate the last node in the row for the given index, and return it.
   */
  const getLastRowNode = (index) => {
    const colCount = calculateColumnCount(1);

    const lastRowItemIndex = index + colCount - (index % colCount);

    const result = items[lastRowItemIndex - 1] || items[items.length - 1];

    return result;
  };

  /**
   * Get the currently active bio node.
   */
  const getActiveBio = () => container.querySelector(BIO_SELECTOR);
  const getActiveTrigger = () => container.querySelector(TRIGGER_SELECTOR);

  /**
   * Check if the currently active bio is the one for the given index.
   */
  const isActiveBioIndex = (index) => {
    const bio = getActiveBio();
    return bio
      ? index === parseInt(bio.getAttribute(INDEX_ATTRIBUTE), 10)
      : false;
  };

  /**
   * Remove the currently active bio if it exists.
   */
  const removeActiveBio = () => {
    const bio = getActiveBio();

    if (bio) {
      const trigger = getActiveTrigger();

      const currentItem = items[window.currentIndex];

      const nonSelectedItems = items.filter((item) => item !== currentItem);

      currentItem.setAttribute("data-icon", "plus");

      nonSelectedItems.forEach((item) =>
        item.setAttribute("data-icon", "plus")
      );

      bio.classList.remove("is-active");

      bio.addEventListener("transitionend", (e) => {
        if (
          !bio.classList.contains("is-active") &&
          e.propertyName === "opacity"
        ) {
          bio.parentNode.removeChild(bio);
        }

        if (
          trigger.classList.contains("new-team-member-list__trigger--is-active")
        ) {
          trigger.classList.remove("new-team-member-list__trigger--is-active");
        }
      });
    }
  };

  /**
   * Add new bio for the current target.
   */
  const addBio = ({ target, index, isKeyboard }) => {
    // Get item content data and create the node.
    const bio = createBioNode({
      ...getContentData(target),
      index,
      image: target.parentElement.querySelector("img").outerHTML,
      indicatorPosition: calculateHorizontalCenter(target, index),
    });

    target.setAttribute("data-icon", "cross");

    // Append bio node after the row's last item's parent, while making sure
    // the `sizes`-attribute is more in line with reality.
    bio.querySelector("img").setAttribute("sizes", "320px");

    container.insertBefore(
      bio,
      getLastRowNode(index).parentNode.nextElementSibling
    );

    window.setTimeout(() => bio.classList.add("is-active"), 0);

    // Focus on bio if the `click` was triggered by something other than a
    // pointer (for example a keyboard).
    if (isKeyboard) {
      bio.focus();
      // Scroll into view when the bio top is mostly out of view.
    } else if (
      bio.getBoundingClientRect().top > window.innerHeight - 100 &&
      typeof bio.scrollIntoView === "function"
    ) {
      bio.scrollIntoView({
        behavior: "smooth",
        block: matchesBreakpoint("small") ? "center" : "start",
      });
    }

    // Add basic close listeners.
    bio
      .querySelector("button")
      .addEventListener("click", (e) => removeActiveBio(), { once: true });
    bio.addEventListener("keyup", (e) => {
      if (isEscapeKey(e)) {
        removeActiveBio();
      }
    });
  };

  /**
   * Handle item click.
   */
  const itemClickHandler = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    const index = items.findIndex((item) => item === target);

    if (
      target.parentElement.classList.contains(
        "new-team-member-list__trigger--is-active"
      )
    ) {
      target.parentElement.classList.remove(
        "new-team-member-list__trigger--is-active"
      );
    } else {
      target.parentElement.classList.add(
        "new-team-member-list__trigger--is-active"
      );
      window.currentIndex = index;
    }

    // Remove possible currently active bio, and stop if the currently
    // clicked item is the one that's been expanded (a.k.a. collapse it).
    removeActiveBio();
    if (isActiveBioIndex(index)) {
      return;
    }
    addBio({
      target,
      index,
      isKeyboard: e.detail === 0,
    });
  };

  /**
   * Limit the amount of visible items based on screen size and amount of rows
   * that need to be shown. Our setup is based on a grid with a dynamic amount
   * of rows and columns, which makes it hard (or impossible) to do this via CSS.
   */
  const updateVisibleItems = () => {
    const colCount = calculateColumnCount();

    const rowLimit = matchesBreakpoint("huge") ? 2 : 3;
    items.forEach((item, index) => {
      item.parentNode.setAttribute(
        "aria-hidden",
        (index >= colCount * rowLimit).toString()
      );
    });
  };

  /**
   * Called by observer.
   * Removes event listener that hides team members on window resize.
   */
  const removeEventListener = (handleResize) => () => {
    window.removeEventListener("resize", handleResize);
    removeActiveBio();
  };

  /**
   * Escape if the window width didn't change.
   * Prevents iOS scrolling and collapsing the url bar from triggering a resize event.
   */
  const ignoreIosScroll = (fn) => () => {
    if (windowWidth === window.innerWidth) {
      return;
    }

    windowWidth = window.innerWidth;
    fn();
  };

  return {
    init: () => {
      items.forEach((item) => item.addEventListener("click", itemClickHandler));
      window.addEventListener(
        "resize",
        debounce(ignoreIosScroll(removeActiveBio), 300)
      );

      // Limit amount of visible items when in preview mode (a.k.a the flex block).
      if (container.getAttribute(PREVIEW_ATTRIBUTE) === "true") {
        const handleResize = debounce(updateVisibleItems, 300);

        const resizeHandler = ignoreIosScroll(handleResize);
        window.addEventListener("resize", resizeHandler);
        subscribe(teamMember, removeEventListener(resizeHandler));

        updateVisibleItems();
      }
    },
  };
};

export const enhancer = (container) => {
  const list = NewTeamMemberList(container);
  list.init();
};

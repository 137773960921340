/* eslint-disable no-useless-concat */

import { htmlToElement } from "./util";

/**
 * This form uses PayPal Payments Standard
 * See: https://developer.paypal.com/docs/integration/web/
 * See: https://developer.paypal.com/webapps/developer/docs/classic/products/payment-data-transfer/
 *
 * Some values are 'obfuscated' since we're not using hosted buttons, and we try
 * to prevent bots from easily scanning client code for 'PayPal form references'.
 *
 * The most common attack vectors would probably be:
 * — A man-in-the-middle attack, but the website is using HTTPS
 * — A browser extension specifically targeting PayPal forms (or our form)
 * — Modified code on the server, but if anyone has access to the server,
 * the back-end code can be be modified too (so either way your 'fucked')
 */

const OBFUSCATED_POST_ACTION =
  "https://www.pay" + "pal.com/cgi-" + "bin/webscr";
const OBFUSCATED_ACCOUNT = "accounting" + "@" + "theocean" + "cleanup.org";

export const PayPalForm = ({ state, returnUrl }) => {
  const getValueInputs = () => {
    const amount = state.amount_custom ? state.amount_custom : state.amount;
    if (state.frequency === "month") {
      return htmlToElement(`
        <div>
          <input type="hidden" name="cmd" value="${
            "_xcl" + "ick-subs" + "criptions"
          }">
          <input type="hidden" name="a3" value="${amount}">
          <input type="hidden" name="p3" value="1">
          <input type="hidden" name="t3" value="M">
          <input type="hidden" name="src" value="1">
          <input type="hidden" name="bn" value="TheOceanCleanup_Subscribe_WPS_US">
        </div>
      `);
    }
    return htmlToElement(`
      <div>
        <input type="hidden" name="cmd" value="${"_dona" + "tions"}">
        <input type="hidden" name="amount" value="${amount}">
        <input type="hidden" name="quantity" value="1">
        <input type="hidden" name="bn" value="TheOceanCleanup_Donate_WPS_US">
      </div>
    `);
  };

  const createForm = () => {
    const amount = state.amount_custom ? state.amount_custom : state.amount;
    const form = htmlToElement(`
      <form action="${OBFUSCATED_POST_ACTION}" method="post" target="_top">
        <input type="hidden" name="charset" value="utf-8">
        <input type="hidden" name="business" value="${OBFUSCATED_ACCOUNT}">
        <input type="hidden" name="currency_code" value="USD">
        <input type="hidden" name="lc" value="en_US">
        <input type="hidden" name="no_shipping" value="1">
        <input type="hidden" name="return" value="${returnUrl}?fr=${state.frequency}&amt=${amount}">
        <input type="hidden" name="cancel_return" value="${window.location.href}">
        <input type="hidden" name="item_name" value="Donation">
      </form>
    `);
    form.appendChild(getValueInputs());
    return form;
  };

  return {
    createAndSubmitForm() {
      const form = createForm();
      document.body.appendChild(form);
      form.submit();
    },
  };
};
